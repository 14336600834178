import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import { userLoginReducer, userSignupReducer, doctorSignupReducer, otpReducer, doctorInfoReducer,
    doctorSubmitFormReducer, userInfoReducer, userMedicalDetailsReducer, userProfileReducer,
    fetchUserMedicalDetailReducer, doctorAppointmentHistoryReducer, uploadUserMedicalDocumentReducer,
    uploadDoctorCouncilDocumentReducer, doctorProfileReducer,
} from './reducer';

// Combine reducers
const rootReducer = combineReducers({
    userLogin: userLoginReducer,
    userSignup: userSignupReducer,
    userDoctorSignup: doctorSignupReducer,
    otp: otpReducer,
    doctorInfo: doctorInfoReducer,
    doctorSubmitForm: doctorSubmitFormReducer,
    userInfo: userInfoReducer,
    userMedicalDetails: userMedicalDetailsReducer,
    userProfile: userProfileReducer,
    fetchMedicalDetail: fetchUserMedicalDetailReducer,
    doctorAppointmentHistory: doctorAppointmentHistoryReducer,
    uploadUserMedicalDocument: uploadUserMedicalDocumentReducer,
    uploadDoctorCouncilDocument: uploadDoctorCouncilDocumentReducer,
    doctorProfile: doctorProfileReducer,

});

// Create store
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;
