import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';
import { Link, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import welcome from '../../lottiefiles/welcome.json';
import DoctorPopup from './DoctorPopup';
import UserNavBar from '../UserNavBar';
import profile_icon from '../../images/profile_icon.png'

const ViewSpecialty = () => {
  const { specialtyId } = useParams();
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null); // State to store the selected doctor
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        setIsLoading(true); // Set loading state to true
        const response = await api.get(`/doctors?specialty_id=${specialtyId}`);
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          setDoctors(response.data.data);
        }
      } catch (error) {
        // Handle error if the API request fails
      } finally {
        setIsLoading(false); // Set loading state to false after fetching data
      }
    };

    fetchDoctors();
  }, [specialtyId]);

  const openDoctorPopup = (doctor) => {
    setSelectedDoctor(doctor);
  };

  const closeDoctorPopup = () => {
    setSelectedDoctor(null);
  };

  const filteredDoctors = doctors.filter(doctor =>
    doctor.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doctor.middleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doctor.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doctor.doctorId.toString().includes(searchTerm)
  );

  return (
    <>
      <UserNavBar />
      <div className='flex flex-col justify-center items-center bg-white'>
        <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-gray-100 pt-16'>
          <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
            <div className='sm:w-1/2 flex justify-center items-center'>
              <Lottie className='w-full' animationData={welcome} loop={true} />
            </div>
            <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='text-xl  text-center font-bold font-Poppins'>Doctors</h1>
              <p className='text-center font-Poppins'>
                with kenecare, you can meet your doctor at the comfort of your home, make an appointment with your doctor today
              </p>
            </div>
          </div>
        </div>

        <div className='w-full lg:pl-72 flex flex-col justify-center items-center'>

        {/* <div className='w-full max-w-7xl mt-10'>
          <Link type="button" to={'/userDashboard'}
            className="text-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
            <svg className="w-6 h-6 text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
            </svg>
            Back to Dashboard
          </Link>
        </div> */}

        <input
          type="text"
          placeholder="Search by Name"
          onChange={e => setSearchTerm(e.target.value)}
          className="border border-blue-400 rounded sm:w-96 w-64 text-sm p-2 m-2"
        />


        {/* Loading spinner */}
        {isLoading && (
          <div className="w-full h-full flex justify-center items-center">
            <div className="mt-20 loader ease-linear rounded-full border-8 border-t-8 border-gray-200
              h-32 w-32 flex justify-center items-center">
              <p className="text-gray-500 ml-4 text-sm">Loading...</p>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-7xl mt-10 sm:px-3">
          {filteredDoctors.length > 0 ? (
            filteredDoctors.map((doctor) => (
              <div key={doctor.doctorId} className="bg-white rounded-md p-4 shadow-md hover:shadow-lg cursor-pointer"
                onClick={() => openDoctorPopup(doctor)}>
                <img src={doctor.profilePic || profile_icon} alt={doctor.firstName} className="w-full h-56 rounded-md mb-2" />
                <p className="text-center">{`${doctor.title} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`}</p>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center py-8">
              <p className="text-gray-500 font-Poppins">{isLoading ? '' : 'No doctors for this specialty yet.'}</p>
            </div>
          )}
        </div>

        {/* DoctorPopup */}
        {selectedDoctor && <DoctorPopup doctor={selectedDoctor} onClose={closeDoctorPopup} />}
        </div>
      </div>
    </>
  );
};

export default ViewSpecialty;
