import React from 'react';
import { CChart } from '@coreui/react-chartjs';

const RevenueChart = () => {

  // Generate dataset with dynamic backgroundColor based on colors array
  const datasets = [{
    label: 'Revenue Generated',
    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 60],
    backgroundColor: [
      'rgba(255, 99, 132, 0.5)',
      'rgba(54, 162, 235, 0.5)',
      'rgba(255, 206, 86, 0.5)',
      'rgba(75, 192, 192, 0.5)',
      'rgba(153, 102, 255, 0.5)',
      'rgba(255, 159, 64, 0.5)',
      'rgba(255, 99, 132, 0.5)',
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 206, 86)',
      'rgb(75, 192, 192)',
      'rgb(153, 102, 255)',
      'rgb(255, 159, 64)',
      'rgb(255, 99, 132)',
    ],
  }];

  return (
    <div className='w-full sm:px-4 px-2'>
      <CChart
        type="bar"
        data={{
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
          datasets: datasets,
        }}
        labels="months"
        options={{
          plugins: {
            legend: {
              labels: {
                color: 'blue',
              },
            },
          },
          scales: {
            x: {
              grid: {
                color: 'rgba(0,0,0,0.1)',
              },
              ticks: {
                color: '(0,0,0,0.1)',
              },
            },
            y: {
              grid: {
                color: 'rgba(0,0,0,0.1)',
              },
              ticks: {
                color: '(0,0,0,0.1)',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default RevenueChart;
