import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loginUser } from '../../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import ForgottenPassword from './ForgottenPassword';

const LoginSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required('Phone number is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const LoginIfNot = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleSuccess = (message, userType) => {
    setSuccessMessage(message || 'Login successful');
    setTimeout(() => {
      setSuccessMessage('');
      onClose(); // Close the login modal or navigate away
    }, 2000);
    setErrorMessage('');
    if (userType === '2') {
      navigate('/doctorDetailForm');
    } else if (userType === '1') {
      window.location.reload();
    } // Add more cases for different user types if needed
  };

  const handleError = (error) => {
    const { message, errors } = error.response.data;
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        setErrorMessage(error.msg);
      });
    } else if (message) {
      setErrorMessage(message);
    } else {
      setErrorMessage('An error occurred during login.');
    }
    setTimeout(() => setErrorMessage(''), 5000);
    setSuccessMessage('');
  };

  return (
    <>
      {showForgotPassword && <ForgottenPassword setShowForgotPassword={setShowForgotPassword} />}
      <Formik
        initialValues={{ mobileNumber: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          const country_code = '+232';
          const mobileNum = values.mobileNumber.slice(-8);
          values.mobileNumber = country_code + mobileNum;
          try {
            await dispatch(loginUser(values));
            handleSuccess();
          } catch (error) {
            handleError(error);
          }
          setIsLoading(false);
          setSubmitting(false);
        }}
      >
        {() => (
          <Form className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-0">
            <div className='flex justify-center items-center w-full max-w-7xl'>
              <div className="bg-white w-1/2 shadow-sm rounded-lg px-4 pt-6 pb-8 mb-4">
                <h2 className='mb-5 text-md text-gray-600 font-Poppins'>Login to continue </h2>
                {successMessage && (
                  <div className="bg-green-200 p-2 text-green-700 text-xs mb-4 rounded">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="bg-red-200 p-2 text-red-700 text-xs mb-4 rounded">
                    {errorMessage}
                  </div>
                )}
                <div className="mb-4">
                  <label className="block text-gray-600 text-sm font-bold mb-2 font-poppins" htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <Field name="mobileNumber"
                    type="text"
                    className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700
                    leading-tight focus:outline-none focus:shadow-outline" />
                  <ErrorMessage name="mobileNumber" component="div" className="text-red-500 text-xs italic" />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-600 text-sm font-bold mb-2 font-poppins" htmlFor="password">
                    Password
                  </label>
                  <Field name="password" type="password" className="appearance-none border rounded border-blue-300
                    w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                  <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
                </div>
                <p className='mb-3 text-gray-500 font-Poppins text-sm'>If u don't have an account <span className='text-blue-500'>
                  <Link to={'/userSignUp'}>Sign Up</Link> </span></p>
                <div>
                  <p className='text-gray-500 font-Poppins text-sm'>Forgot your password? <span className='text-blue-500 cursor-pointer' onClick={() => setShowForgotPassword(true)}>
                    Reset Password</span></p>
                </div>
                <div className="flex flex-col items-center justify-between">
                  <button type="submit"
                    className="py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Login'}
                  </button>

                  <button
                    onClick={onClose}
                    className="mt-4 w-full py-2 bg-orange-100 text-orange-500 rounded hover:bg-orange-200 hover:text-orange-600"
                    >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default LoginIfNot;
