import React from 'react'
import DoctorChats from '../../components/chats/DoctorChats'
import avatar_1 from '../../images/avatar_1.jpg'
import DoctorNavBar from '../../components/DoctorNavBar';

const DoctorChartPage = () => {
    const chatData = [
        {
          id: 1,
          name: 'Lilly',
          lastSenderName: 'Lilly',
          info: 'Yes i can do it for you',
          unreadCnt: 0,
          unreadDot: false,
          avatar: avatar_1,
          status: 'available',
          messages: [
            {
              model: {
                message: "Hello my friend",
                sentTime: "15 mins ago",
                sender: "Lilly",
                direction: "incoming",
                position: "single"
              },
              date: "Saturday, 30 November 2019"
            },
            // Other messages for Lilly...
          ]
        },
        {
          id: 2,
          name: 'Joe',
          lastSenderName: 'Joe',
          info: 'Yes i can do it for you',
          unreadCnt: 1,
          unreadDot: true,
          avatar: avatar_1,
          status: 'dnd',
          messages: [
            {
              model: {
                message: "Hey there!",
                sentTime: "20 mins ago",
                sender: "Joe",
                direction: "incoming",
                position: "single"
              },
              date: "Saturday, 30 November 2019"
            },
            // Other messages for Joe...
          ]
        },
        // Add more conversation objects as needed...
      ];

      return (
        <div>
            <DoctorNavBar />
            <div className='flex justify-center items-center flex-row lg:pl-72 w-full h-screen lg:px-0 sm:mt-0 sm:pt-0 pt-16 bg-blue-50'>
                <DoctorChats chatData={chatData} />
            </div>

        </div>
      )
    }

export default DoctorChartPage
