import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDoctorProfile } from "../../redux/actions";

const DoctorProfile = ({ userProfile, fetchUserProfile }) => {
    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]); // Correct dependency

    const profileImageUrl = userProfile.data.profilePic
      ? `${userProfile.data.profilePic}`
      : 'default_image_url';

    return (
        <div className="w-full bg-white py-2">
            {userProfile.loading ? (
                <p>Loading...</p>
            ) : userProfile.error ? (
                <p>Error: {userProfile.error}</p>
            ) : (
                <div className="bg-white p-4 shadow rounded-lg">
                    <img
                        src={profileImageUrl}
                        alt="Profile Pic"
                        className="w-20 h-20 rounded-full mx-auto bg-gray-200"
                    />
                    <p className="text-sm text-center mt-4 text-gray-800">
                        {`${userProfile.data.title} ${userProfile.data.firstName} ${userProfile.data.middleName} ${userProfile.data.lastName}`}
                    </p>
                    <p className="text-center text-xs text-gray-500">Specialization: {userProfile.data.specialization}</p>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    userProfile: state.doctorProfile, // Ensure this path matches your actual Redux state structure
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserProfile: () => dispatch(fetchDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfile);
