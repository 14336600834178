import React, { useState, useEffect } from 'react';

const dummyQA = [
  { question: "What is the best way to whiten teeth?", answer: "The best way to whiten teeth is by using professional whitening treatments provided by your dentist. Over-the-counter products can also help, but professional treatments are more effective." },
  { question: "How often should I visit the dentist?", answer: "It is recommended to visit the dentist at least twice a year for regular check-ups and cleanings." },
  { question: "What should I do if I have a toothache?", answer: "If you have a toothache, you should see a dentist as soon as possible. In the meantime, rinse your mouth with warm salt water and take over-the-counter pain relievers." },
];

function ConsultQA() {
  const [userQuestion, setUserQuestion] = useState('');
  const [filteredQA, setFilteredQA] = useState(dummyQA);
  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState(false);

  useEffect(() => {
    // Simulate a data fetch
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust this timeout as needed
  }, []);

  const handleQuestionChange = (event) => {
    setUserQuestion(event.target.value);
    filterQuestions(event.target.value);
    setIsQuestionSubmitted(false);
  };

  const filterQuestions = (query) => {
    if (query === '') {
      setFilteredQA(dummyQA);
    } else {
      setFilteredQA(dummyQA.filter(qa => qa.question.toLowerCase().includes(query.toLowerCase())));
    }
  };

  const handleQuestionSubmit = () => {
    // Simulate question submission
    setIsQuestionSubmitted(true);
    setUserQuestion('');
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">Consultation Q&A</h2>
      <input
        type="text"
        value={userQuestion}
        onChange={handleQuestionChange}
        placeholder="Ask a question..."
        className="w-full p-3 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {isLoading ? (
        <div className="text-center text-gray-500">
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          {filteredQA.length > 0 ? (
            filteredQA.map((qa, index) => (
              <div key={index} className="mb-4">
                <p className="font-semibold">{qa.question}</p>
                <p className="text-gray-700">{qa.answer}</p>
              </div>
            ))
          ) : (
            <div>
              <p className="text-red-500 mb-4">No matching questions found.</p>
              <button
                onClick={handleQuestionSubmit}
                className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Submit your question
              </button>
              {isQuestionSubmitted && (
                <p className="mt-4 text-green-500">Your question has been submitted!</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConsultQA;
