import React from 'react'
import DoctorNavBar from '../../components/DoctorNavBar';
import Lottie from 'lottie-react';
import medical_document from'../../lottiefiles/medical_document.json'

const PatentMedicalDocuments = () => {
  return (
    <div className='bg-white overflow-x-hidden'>
    <DoctorNavBar/>

    <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-gray-100 pt-16'>
      <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
        <div className='sm:w-1/2 flex justify-center items-center'>
          <Lottie className='w-full' animationData={medical_document} loop={true} />
        </div>
        <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
            <h1 className='text-xl text-center font-bold font-Poppins'>Patient Medical Documents</h1>
            <p className='text-center font-Poppins'>
              View and manage your patient's medical documents here.
            </p>
          </div>
      </div>
    </div>

      <div className='lg:ml-72 mt-14 mb-10 lg:px-10 px-3'>
          {/* <DocumentTableComponent/> */}
      </div>

  </div>
  )
}

export default PatentMedicalDocuments
