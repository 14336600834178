import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api } from '../../utils/Api';
import profile_icon from '../../images/profile_icon.png'

const validationSchema = Yup.object().shape({
  patientName: Yup.string().required('Patient name is required'),
  patientNumber: Yup.string().required('Patient number is required'),
  doctorId: Yup.number().required('Doctor is required'), // Make doctorId required
  specialtyId: Yup.number().required('Specialty is required'), // Make specialtyId required
  symptoms: Yup.string().required('Symptoms are required'),
  appointmentType: Yup.string().required('Appointment type is required'),
  appointmentDate: Yup.date().required('Appointment date is required'),
  appointmentTime: Yup.string().required('Appointment time is required'),
  timeSlotId: Yup.number().required('Time slot is required'), // Make timeSlotId required
});


const DoctorAppointmentForm = ({ onClose }) => {
  const [selectedDoctorId, setSelectedDoctorId] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [specialties, setSpecialties] = useState([]);

  const appointType = [
    { appointTypeId: 1, name: 'online_consultation' },
    { appointTypeId: 2, name: 'patient_visit' },
    { appointTypeId: 3, name: 'doctor_visit' },
  ];

  const selectedTime = [
    { timeId: 1, appointmentTime: '07:00' },
    { timeId: 2, appointmentTime: '07:30' },
    { timeId: 3, appointmentTime: '08:00' },
    { timeId: 4, appointmentTime: '08:30' },
    { timeId: 5, appointmentTime: '09:00' },
    { timeId: 6, appointmentTime: '09:30' },
    { timeId: 7, appointmentTime: '10:00' },
    { timeId: 8, appointmentTime: '10:30' },
    { timeId: 9, appointmentTime: '11:00' },
    { timeId: 10, appointmentTime: '11:30' },
    { timeId: 11, appointmentTime: '12:00' },
    { timeId: 12, appointmentTime: '12:30' },
    { timeId: 13, appointmentTime: '13:00' },
    { timeId: 14, appointmentTime: '13:30' },
    { timeId: 15, appointmentTime: '14:00' },
    { timeId: 16, appointmentTime: '14:30' },
    { timeId: 17, appointmentTime: '15:00' },
    { timeId: 18, appointmentTime: '15:30' },
    { timeId: 19, appointmentTime: '16:00' },
    { timeId: 20, appointmentTime: '16:30' },
    { timeId: 21, appointmentTime: '17:00' },
    { timeId: 22, appointmentTime: '17:30' },
    { timeId: 23, appointmentTime: '18:00' },
  ]

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep === 2;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    api.get('/specialties')
      .then((response) => {
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          setSpecialties(response.data.data);
        }
      })
      .catch((error) => {
        // Handle error if the API request fails
      });
  }, []);

  const handleSpecialtyChange = (value, setFieldValue) => {
    setFieldValue('specialtyId', value);
    api.get(`/doctors?specialty_id=${value}`)
      .then((response) => {
        if (response.data.status === 0) {
          // Handle error if needed
        } else {
          setDoctors(response.data.data);
        }
      })
      .catch((error) => {
        // Handle error if the API request fails
      });
  };

  const handleAppointmentTypeChange = (name, setFieldValue) => {
    setFieldValue('appointmentType', name);
  };

  const handleAppointmentTimeChange = (name, setFieldValue) => {
    setFieldValue('appointmentTime', name);
  };

  useEffect(() => {
    let messageTimeout;
    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    if (isLastStep) {
      values.doctorId = selectedDoctorId;
      console.log(values);
      try {
        const response = await api.post('/patients/appointments', values);
        const data = response.data.data;
        if (data && data.paymentUrl) {
          setSuccessMessage('Appointment booked successfully. Redirecting to payment...');
          setErrorMessage('');
          setTimeout(() => {
            window.location.href = data.paymentUrl;
            onClose();
          }, 3000);
          actions.resetForm();
          setSelectedDoctorId('');
        } else {
          setSuccessMessage('Appointment booked successfully.');
          setErrorMessage('');
        }
      } catch (error) {
        const message = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while submitting the appointment details.';
        setErrorMessage(message);
        setSuccessMessage('');
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
    setIsLoading(false);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const renderDoctorSelection = () => {
    const filteredDoctors = doctors ? doctors.filter(doctor =>
      (doctor.firstName && doctor.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (doctor.lastName && doctor.lastName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (doctor.doctorId && doctor.doctorId.toString().includes(searchQuery))
    ) : [];

    return (
      <div className='flex flex-col w-full justify-center items-center'>
        <p className='text-gray-500 text-xs mb-3'>Select from the list of doctors.</p>

        <div className="flex justify-center w-full mb-4">
          <input
            type="text"
            placeholder="Search by doctor name or ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="appearance-none border border-blue-300 rounded sm:w-1/2 py-2 px-3 text-gray-700 leading-tight
              focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="w-full grid items-center sm:grid-cols-3 grid-cols-2 gap-4 bg-gray-50 rounded p-3 max-h-[50rem]
          overflow-y-auto justify-items-center align-items-center">
          {filteredDoctors.map(doctor => (
            <div
              key={doctor.doctorId}
              onClick={() => setSelectedDoctorId(doctor.doctorId)}
              className={`flex flex-col justify-center items-center border hover:border-blue-300 shadow-md bg-white
              rounded-md cursor-pointer px-3 py-2 w-[11rem] ${selectedDoctorId === doctor.doctorId ? 'border-blue-500' : 'border-transparent'}`}
            >
              <img src={doctor.profilePic || profile_icon} alt={doctor.name} className="sm:w-16 sm:h-16 w-9 h-9 rounded-full bg-gray-200 mb-2" />
              <p className='text-xs text-gray-700'>{doctor.title} {doctor.firstName} {doctor.lastName}</p>
              <p className='text-xs text-gray-500'>Fee: {doctor.consultationFee}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderStepFields = (step, setFieldValue) => {
    switch (step) {
      case 0:
        return (
          <>
            <FieldComponent name="patientName" label="Patient Name" type="text" />
            <FieldComponent name="patientNumber" label="Patient Number" type="text" />
            <DropdownComponent name="specialtyId" label="Specialty" options={specialties} onChange={(value) => handleSpecialtyChange(value, setFieldValue)} />
            <FieldComponent name="symptoms" label="Symptoms" type="textarea" />
          </>
        );
      case 1:
        return renderDoctorSelection();
      case 2:
        return (
          <>
            <DropdownComponent name="appointmentType" label="Appointment Type" options={appointType} onChange={(value) => handleAppointmentTypeChange(value, setFieldValue)}  />
            <FieldComponent name="appointmentDate" label="Appointment Date" type="date" id='appointmentDate'/>
            <DropdownComponent name="appointmentTime" label="Appointment Time" options={selectedTime} onChange={(value) => handleAppointmentTimeChange(value, setFieldValue)} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={{
        patientName: '',
        patientNumber: '',
        doctorId: '',
        specialtyId: '',
        symptoms: '',
        appointmentType: '',
        appointmentDate: '',
        appointmentTime: '',
      }}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form >
          <div className=" bg-white px-5 rounded-lg shadow sm:py-10 py-3 w-full  sm:max-w-5xl">
            <button
              type="button"
              className=" text-red-600 hover:text-red-500 focus:outline-none"
              onClick={onClose} // Call onClose function when the button is clicked
            >
              Close
            </button>

            <h2 className="sm:text-xl font-bold text-center mb-4 text-sm">Doctor Appointment Form</h2>

            {successMessage && (
              <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                {errorMessage}
              </div>
            )}

            <div className={`mb-4 sm:grid sm:grid-cols-1 gap-4`}>
              {renderStepFields(currentStep, setFieldValue)}
            </div>

            <div className='mt-5 flex gap-14'>
              {currentStep > 0 && (
                <button type="button"
                class="py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                  rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                  focus:ring-gray-200 w-full mt-5 font-Poppins"
                onClick={() => setCurrentStep(currentStep - 1)}>Previous</button>
              )}
              <button type="submit"
                class="py-2.5 px-5 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                focus:ring-gray-200 w-full mt-5 font-Poppins"
                disabled={isLoading}
                  >
                {isLoading ? 'Loading...' : (isLastStep ? 'Submit' : 'Next')}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

  const FieldComponent = ({ name, label, type }) => (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
      <Field name={name} as={type === 'textarea' ? 'textarea' : 'input'} type={type !== 'textarea' ? type : undefined}
      className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      required />
      <ErrorMessage name={name} component="div" className="text-sm text-red-600" />
    </div>
  );

const DropdownComponent = ({ name, label, options, onChange }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
    <select
      name={name}
      className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      onChange={(e) => onChange(e.target.value)} required
    >
      <option value="">Select</option>
      {options && options.map(option => {
        return (
          <option key={option.appointTypeId || option.specialtyId || option.timeId} value={option.name || option.specialtyId || option.appointmentTime}>
            {name === "appointmentType" ? option.name : option.specialtyName || option.appointmentTime}
          </option>
        );
      })}
    </select>
    <ErrorMessage name={name} component="div" className="text-sm text-red-600" />
  </div>
);

export default DoctorAppointmentForm;
