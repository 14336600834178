import React, { useState, useEffect } from "react";
import { api } from '../../../utils/Api'

const ChangePhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch current phone number from endpoint
    api.get('/patients/profile')
      .then(response => {
        const { data } = response;
        setPhoneNumber(data.data.mobileNumber);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching phone number');
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send updated phone number to backend
    console.log("New Phone Number:", phoneNumber);
  };

  if (loading) {
    return <p className="text-gray-500">Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full mx-auto bg-gray-50 rounded-md shadow-md max-w-7xl"
    data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-600 mb-2">Change Phone Number</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">New Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleChange}
            className="appearance-none border rounded border-blue-300 w-full py-2 px-3 text-gray-700 leading-tight
            focus:outline-none focus:shadow-outline mt-3"
            placeholder="Enter new phone number"
          />
          <button
            type="submit"
            className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium
            rounded text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Change Phone Number
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePhoneNumber;
