import React from 'react'
import UserProfileDetail from '../../components/userDashboard/UserProfileDetail'
import UserNavBar from '../../components/UserNavBar'
import profile_bg from '../../images/profile_bg.jpg';

const UserProfileDetails = () => {
  return (
    <div className='w-full h-screen bg-blue-100 flex justify-center items-center'>
      <UserNavBar/>
      <div className='w-full lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:gap-0 gap-7'>
        <UserProfileDetail/>
      </div>


    </div>
  )
}

export default UserProfileDetails

