import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    selectedDoctor: Yup.string().required('Please select a doctor'),
    note: Yup.string().required('Please enter a note'),
});

function ShareDocumentPopup({ documentId, onClose }) {
    const [doctors, setDoctors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                const response = await api.get('/doctors');
                if (response.data.status === "success") {
                    setDoctors(response.data.data);
                } else {
                    setDoctors([]);
                }
            } catch (error) {
                console.error("Error fetching doctors:", error);
                setDoctors([]);
            }
        };

        fetchDoctors();
    }, []);


    useEffect(() => {
        let messageTimeout;
        if (successMessage) {
          messageTimeout = setTimeout(() => {
            setSuccessMessage('');
          }, 4000);
        }

        if (errorMessage) {
          messageTimeout = setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        }

        return () => clearTimeout(messageTimeout);
      }, [successMessage, errorMessage]);



    const handleShare = async (values, actions) => {
        try {
            const response = await api.post('/patients/shared-docs', {
                documentId: documentId,
                doctorId: values.selectedDoctor,
                note: values.note
            }).catch(error => {
                throw error
            });
            if (response.data && response.data.status === "success") {
                setSuccessMessage('User details submitted successfully.'); // Display success message
                onClose();
                } else {
                throw new Error('Sharing document was not successful.');
            }
        } catch ({response}) {
            console.error('Error sharing document', response);
            if(response.status === 304) {
                setErrorMessage('This document has already been shared with the selected doctor.');
            } else {
            setErrorMessage(response ||'An error occurred while sharing document.');
            }
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 lg:pl-72">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-lg font-semibold mb-4">Share Document</h2>

                <Formik
                    initialValues={{ selectedDoctor: '', note: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleShare}
                >
                    {({ isSubmitting }) => (
                        <Form>

                            {successMessage && (
                            <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded text-xs">
                                {successMessage}
                            </div>
                            )}

                            {errorMessage && (
                                <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded text-xs">
                                    {errorMessage.message || errorMessage.toString()}
                                </div>
                            )}

                            <div className="mb-4">
                                <label htmlFor="selectedDoctor">Select Doctor</label>
                                <Field as="select" name="selectedDoctor" className="appearance-none border
                                    border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline">
                                    <option value="">Select Doctor</option>
                                    {doctors.map(doctor => (
                                        <option key={doctor.doctorId} value={doctor.doctorId}>{doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName} ID:{doctor.doctorId}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="selectedDoctor" component="div" className="text-red-500" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="note">Note</label>
                                <Field as="textarea" name="note" className="appearance-none border border-blue-300
                                    rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none
                                    focus:shadow-outline"
                                />
                                <ErrorMessage name="note" component="div" className="text-red-500" />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none
                                        bg-white rounded-lg border border-blue-300  hover:bg-red-100
                                        hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-5
                                        font-Poppins"
                                    onClick={onClose}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none
                                        bg-white rounded-lg border border-blue-300  hover:bg-gray-100
                                        hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-5
                                        font-Poppins"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Sharing...' : 'Share'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ShareDocumentPopup;
