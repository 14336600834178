import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../redux/actions";
import UserMedicalDetails from "./UserMedicalDetails";
import profile_icon from '../../images/profile_icon.png'
import { api } from "../../utils/Api";
import { Link } from 'react-router-dom';


const UserProfileDetail = ({ userProfile, fetchUserProfile }) => {
  useEffect(() => {
    fetchUserProfile(); // Dispatch an action to fetch user profile data
  }, [fetchUserProfile]);

  const profileImageUrl = userProfile.data.profilePic
  ? `${userProfile.data.profilePic}`
  : 'default_image_url';

  return (
    <div className="w-full h-screen flex justify-center items-center py-6">
      <div
        className="lg:w-1/2 w-full flex justify-center items-center py-10 rounded shadow-lg"
        style={{ backgroundImage: 'linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)' }}
      >
      {/* <h1 className="text-2xl font-bold mb-4">User Profile</h1> */}
      {userProfile.loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
          h-32 w-32 flex justify-center items-center"
          >
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
        </div>
      ) : userProfile.error ? (
        <p>Error: {userProfile.error}</p>
      ) : (
        <div className="flex flex-col w-full justify-center items-center">
          <img
            src={profileImageUrl || profile_icon}
            alt="Profile Pic"
            className="sm:w-72 sm:h-72 w-52 h-52 rounded-full bg-white p-1 start-0"
          />

          <div className="flex flex-col">
            <div className="ml-0 p-4 flex flex-col w-full">
              <p className="text-lg font-semibold mt-2 text-gray-700">
              {`${userProfile.data.firstName} ${userProfile.data.middleName} ${userProfile.data.lastName}`}
              </p>
                <p className="font-semibold text-sm text-gray-700">Patient ID: {userProfile.data.patientId}</p>
                <p className="text-sm text-gray-500">Gender: {userProfile.data.gender}</p>

                <p className="text-sm text-gray-500">Date of birth: {userProfile.data.dob}</p>

                <div className=" flex gap-3 mt-2">
                  <div className="flex gap-1">
                  <svg
                  class="flex-shrink-0 w-5 h-5 text-blue-500 transition duration-75  group-hover:text-blue-400"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8 4a2.6 2.6 0 0 0-2 .9 6.2 6.2 0 0 0-1.8 6 12 12 0 0 0 3.4 5.5 12 12 0 0 0 5.6 3.4 6.2 6.2 0 0 0 6.6-2.7 2.6 2.6 0 0 0-.7-3L18 12.9a2.7 2.7 0 0 0-3.8 0l-.6.6a.8.8 0 0 1-1.1 0l-1.9-1.8a.8.8 0 0 1 0-1.2l.6-.6a2.7 2.7 0 0 0 0-3.8L10 4.9A2.6 2.6 0 0 0 8 4Z"/>
                  </svg>
                    <p className="text-xs text-blue-500 font-semibold">{userProfile.data.mobileNumber}</p>
                  </div>

                </div>
            </div>

            <div className="w-full">
              <UserMedicalDetails/>
            </div>
            <div className="flex flex-col w-full justify-center items-center border-t border-gray-100 mt-10">
              <Link to="/settings_page" className="text-blue-800 font-semibold text-sm mt-5">
              <svg class="w-6 h-6 text-blue-900 transition duration-75 hover:text-gray-600 mt-5"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M5 8a4 4 0 1 1 7.796 1.263l-2.533 2.534A4 4 0 0 1 5 8Zm4.06 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h2.172a2.999 2.999 0 0 1-.114-1.588l.674-3.372a3 3 0 0 1 .82-1.533L9.06 13Zm9.032-5a2.907 2.907 0 0 0-2.056.852L9.967 14.92a1 1 0 0 0-.273.51l-.675 3.373a1 1 0 0 0 1.177 1.177l3.372-.675a1 1 0 0 0 .511-.273l6.07-6.07a2.91 2.91 0 0 0-.944-4.742A2.907 2.907 0 0 0 18.092 8Z" clip-rule="evenodd"/>
              </svg>
              </Link>
            </div>
          </div>

        </div>

      )}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile, // Replace with your actual Redux state
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()), // Replace with your actual action creator
});

export default connect(mapStateToProps, mapDispatchToProps) (UserProfileDetail)

