import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDoctorProfile } from "../../redux/actions";
import { api } from "../../utils/Api";

const DoctorDetailProfile = ({ userProfile, fetchUserProfile }) => {
    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]); // Correct dependency

    const profileImageUrl = userProfile.data.profilePic
      ? `${userProfile.data.profilePic}`
      : 'default_image_url';
  return (
    <div className="w-full flex justify-start items-start">
    {/* <h1 className="text-2xl font-bold mb-4">User Profile</h1> */}
    {userProfile.loading ? (
      <p>Loading...</p>
    ) : userProfile.error ? (
      <p>Error: {userProfile.error}</p>
    ) : (
      <div className="flex flex-col w-full justify-start items-start">
        <img
          src={profileImageUrl}
          alt="Profile Pic"
          className="sm:w-72 w-full h-60 rounded-lg bg-white p-1 start-0"
        />

        <div className="flex sm:flex-row flex-col w-full max-w-7xl">
        <div className="ml-0 bg-white p-4 flex flex-col w-full">
          <p className="text-lg font-semibold mt-2 text-gray-700">
          {`${userProfile.data.title} ${userProfile.data.firstName} ${userProfile.data.middleName} ${userProfile.data.lastName}`}
          </p>
            <p className="font-semibold text-sm text-gray-700">Doctor ID: {userProfile.data.doctorId}</p>
            <p className="text-sm text-gray-500">Gender: {userProfile.data.gender}</p>

            <p className="text-sm text-gray-500">Specialization: {userProfile.data.specialization}</p>
            <p className=" text-sm text-gray-500">Qualifications: {userProfile.data.qualifications}</p>
            <p className="text-sm text-gray-500">Years of Experience: {userProfile.data.yearOfExperience}</p>
            <p className="text-sm text-gray-500">Consultation Fees: {userProfile.data.consultationFees}</p>

            <div className=" flex gap-3 mt-2">
              <div className="flex gap-1">
              <svg
              class="flex-shrink-0 w-5 h-5 text-orange-500 transition duration-75  group-hover:text-orange-400"
              aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8 4a2.6 2.6 0 0 0-2 .9 6.2 6.2 0 0 0-1.8 6 12 12 0 0 0 3.4 5.5 12 12 0 0 0 5.6 3.4 6.2 6.2 0 0 0 6.6-2.7 2.6 2.6 0 0 0-.7-3L18 12.9a2.7 2.7 0 0 0-3.8 0l-.6.6a.8.8 0 0 1-1.1 0l-1.9-1.8a.8.8 0 0 1 0-1.2l.6-.6a2.7 2.7 0 0 0 0-3.8L10 4.9A2.6 2.6 0 0 0 8 4Z"/>
              </svg>
                <p className="text-xs text-blue-500">{userProfile.data.mobileNumber}</p>
              </div>

              <div className="flex gap-1">
              <svg
                class="flex-shrink-0 w-5 h-5 text-orange-500 transition duration-75  group-hover:text-orange-400"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z"/>
                <path d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z"/>
              </svg>
                <p className="text-xs text-blue-500">{userProfile.data.email}</p>
              </div>

              <div className="flex gap-1">
              <svg
                class="flex-shrink-0 w-5 h-5 text-orange-500 transition duration-75  group-hover:text-orange-400"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
              </svg>
                <p className="text-xs text-blue-500">{userProfile.data.city}</p>
              </div>

            </div>
        </div>
            <div className="sm:w-1/2 w-full max-w-profile-summary ml-3">
              <p className="text-sm font-semibold text-gray-700">Professional Summary</p>
              <p className="text-justify px-3 mt-2 text-xs text-gray-500">{userProfile.data.professionalSummary}</p>
            </div>

        </div>

      </div>

    )}

  </div>
  )
}

const mapStateToProps = (state) => ({
    userProfile: state.doctorProfile, // Ensure this path matches your actual Redux state structure
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserProfile: () => dispatch(fetchDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps) (DoctorDetailProfile);
