import React, { useState } from 'react';

const TextFolding = ({ initialText, foldThreshold = 200, children }) => {
    const [folded, setFolded] = useState(true);

    const toggleFold = () => {
        setFolded(!folded);
    };

    return (
        <div className="w-full">
            <div className="overflow-hidden">
                <p className="text-gray-500">
                    {folded ? `${initialText.substring(0, foldThreshold)}...` : initialText}
                </p>
                {initialText.length > foldThreshold && (
                    <button
                        onClick={toggleFold}
                        className="text-blue-500 hover:text-blue-700 mt-2 focus:outline-none"
                    >
                        {folded ? 'Read more' : 'Read less'}
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default TextFolding;
