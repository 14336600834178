import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  // Check if the user is logged in
  const isLoggedIn = token && (type === "1" || type === "2");

  return (
    <>
      {isLoggedIn ? (
        <Outlet /> // If the user is logged in, render the child components
      ) : (
        <Navigate to="/" /> // If not, redirect to the login page
      )}
    </>
  );
};

export default PrivateRoute;
