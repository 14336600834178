import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StarRating from '../../utils/StarRating';
import profile_icon from '../../images/profile_icon.png'
import { Link } from 'react-router-dom';



const OutTopDoctors = () => {
  const [topDoctors, setTopDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    const fetchTopDoctors = async () => {
      setIsLoading(true); // Set loading to true at the start of the API call
      try {
        const response = await api.get('/doctors');
        if (response.data.status === 0) {
          // Handle error if needed
          setIsLoading(false); // Ensure to set loading to false if an error occurs
        } else {
          setTopDoctors(response.data.data.slice(0, 5));
          setIsLoading(false); // Set loading to false after fetching data
        }
      } catch (error) {
        setIsLoading(false); // Ensure to set loading to false on API error
      }
    };

    fetchTopDoctors();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
      justifyContent: 'center',
    },
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
            h-32 w-32 flex justify-center items-center">
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
      </div>
    );
  }


  return (
    <div className="flex flex-col w-full max-w-7xl justify-center items-center py-8 ">
      <p className="text-xl font-bold mb-2 text-gray-700">Top Rated Doctors</p>
      <p className='mb-8 text-sm text-gray-700'>
        Discover our top-rated five doctors who excel in providing exceptional healthcare services.
        </p>
      <Carousel
          responsive={responsive}
          infinite
          draggable
          swipeable
          centerMode={false}
          autoPlay={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={['tablet', 'mobile']}
          className='w-full z-0'
        >
        {topDoctors.slice(0,5).map((doctor) => (
          <Link to={`/doctorDetails/${doctor.doctorId}}`} target='_blank' >
            <div key={doctor.id} className="flex flex-row bg-white h-40 sm:w-96  rounded-md shadow">
              <div className="w-1/2 bg-gray-200 rounded-l-md">
              <img
                src={doctor.profilePic || profile_icon}
                alt={doctor.name}
                className="flex object-cover object-center rounded-l-md h-full w-full"
              />
              </div>

              <div className='flex flex-col justify-center ml-2'>
                  <h2 className="font-semibold text-xs">{doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName}</h2>
                  <p className="text-gray-600 mb-2 text-xs">Specialization: {doctor.specialization}</p>
                  <p className="text-gray-600 mb-2 text-xs">Consultation fee: {doctor.consultationFee}</p>
                  <p className="text-gray-600 mb-2 text-xs">ID: {doctor.doctorId}</p>
                  <p className="text-gray-600 text-xs">Ratings: <StarRating rating={doctor.rating} /></p>
              </div>
            </div>
          </Link>
        ))}
        </Carousel>

    </div>
  );
};

export default OutTopDoctors;
