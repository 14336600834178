import React, { useEffect, useState } from 'react';
import { api } from '../../utils/Api';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const PostponedAppointments = () => {
    const [appointments, setAppointments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [isActionModalVisible, setIsActionModalVisible] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const [actionFeedback, setActionFeedback] = useState('');

    const [isPostponeFormVisible, setIsPostponeFormVisible] = useState(false);



    const handleAction = (id) => {
      setSelectedAppointmentId(id);
      setActionFeedback('');
      setIsActionModalVisible(true);
    };

    const handleApprove = async () => {
      if (selectedAppointmentId) {
        try {
          const response = await api.patch(`/doctors/appointments/${selectedAppointmentId}/approved`);
          if (response.status === 304) {
            setActionFeedback('This appointment has already been approved.');
          } else {
            setActionFeedback('Appointment approved successfully.');
          }
          setTimeout(() => setActionFeedback(''), 3000);
          setIsActionModalVisible(false);
        } catch (error) {
          setActionFeedback('An error occurred while approving the appointment.');
          console.error("Error approving appointment:", error);
          setTimeout(() => setActionFeedback(''), 3000);
        }
      }
    };

    const handlePostponeSubmit = async (values) => {
      if (selectedAppointmentId) {
        try {
          const response = await api.patch(`/doctors/appointments/${selectedAppointmentId}/postpone`, {
            reason: values.reason,
            newDate: values.newDate,
          });
          if (response.status === 304) {
            setActionFeedback('This appointment has already been approved.');
          } else  if(response.status.data) {
            setActionFeedback('This appointment has already been postponed.');
          }
          else {
            setActionFeedback('Appointment approved successfully.');
          }
          setActionFeedback('Appointment postponed successfully.');
          setTimeout(() => setActionFeedback(''), 3000);
        } catch (error) {
          const message = error.response.data;
          setActionFeedback(message);
          setTimeout(() => setActionFeedback(''), 3000);
        } finally {
          setIsPostponeFormVisible(false);
          // Optionally, refresh the appointments list here
        }
      }
    };


    const PostponeForm = ({ isOpen, onClose, onSubmit, initialDate, feedback }) => {

      if (!isOpen) return null;

      const validationSchema = Yup.object().shape({
        reason: Yup.string().required('A reason is required'),
        newDate: Yup.date().min(new Date(), 'The date must be in the future').required('A new date is required'),
      });

      return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white">
            <Formik
              initialValues={{ reason: '', newDate: initialDate }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue }) => (
                <Form>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Postpone Appointment</h3>
                  {feedback && <p className="text-sm text-red-500 bg-red-200 px-1 py-2 rounded">{feedback}</p>}
                  <div className="mb-4">
                    <label htmlFor="reason" className="block text-sm font-medium text-gray-700">Reason</label>
                    <Field name="reason" as="textarea" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"/>
                    <ErrorMessage name="reason" component="div" className="text-red-500 text-sm"/>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="newDate" className="block text-sm font-medium text-gray-700">New Date</label>
                    <Field name="newDate" type="date" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"/>
                    <ErrorMessage name="newDate" component="div" className="text-red-500 text-sm"/>
                  </div>

                  <div className="flex items-center justify-between gap-4">
                    <button type="submit" className="inline-flex justify-center rounded border border-transparent px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Submit</button>
                    <button onClick={onClose} type="button" className="inline-flex justify-center rounded border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    };

    const ActionModal = ({ onClose, onApprove, onPostpone, feedback }) => {
      return (
        <div className={`fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full flex justify-center items-center
          ${!isActionModalVisible && 'hidden'}`}>
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md  bg-white">
            <div className="mt-3 text-center">
            {feedback && <p className="text-sm text-red-500 bg-red-200 px-1 py-2 rounded">{feedback}</p>}
              <h3 className="text-lg leading-6 font-medium text-gray-900">Appointment Action</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">Do you want to approve or postpone this appointment?</p>
              </div>
              <div className="items-center gap-4 mt-4">
                <button onClick={onApprove} className="inline-flex justify-center w-full rounded border border-transparent  px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">Approve</button>
                <button onClick={() => { setIsActionModalVisible(false); setIsPostponeFormVisible(true); }}  className="inline-flex justify-center w-full rounded border border-transparent px-4 py-2 bg-yellow-500 text-base font-medium text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm">Postpone</button>
                <button onClick={onClose} className="inline-flex justify-center w-full rounded border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      );
    };


    useEffect(() => {
      const fetchAppointments = async (page) => {
        try {
          const response = await api.get(`/doctors/appointments?page=${page}&limit=20`);
          const pendingAppointments = response.data.data.filter(appointment => appointment.appointmentStatus === 'postponed');
            setAppointments(pendingAppointments);
          // Assuming the API response includes total pages info, adjust if necessary
          setTotalPages(response.data.totalPages || 0);
        } catch (error) {
          console.error("Error fetching appointments:", error);
        }
      };

      fetchAppointments(currentPage);
      const intervalId = setInterval(fetchAppointments, 5000, currentPage);
      return () => clearInterval(intervalId);
    }, [currentPage]);

    const handlePageClick = (page) => {
      setCurrentPage(page);
    };

    // Dynamically generate page numbers based on totalPages
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="overflow-x-scroll lg:px-0 px-0">
        {appointments.length > 0 ? (
      <div className="relative shadow-md sm:rounded-lg overflow-x-scroll">
        <table className="w-full text-sm text-left max-w-appointment-max-wit">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">ID</th>
              <th scope="col" className="py-3 px-6">Username</th>
              <th scope="col" className="py-3 px-6">Patient Name</th>
              <th scope="col" className="py-3 px-6">Symptoms</th>
              <th scope="col" className="py-3 px-6">Mobile Number</th>
              <th scope="col" className="py-3 px-6">Payment Method</th>
              <th scope="col" className="py-3 px-6">Appointment Date</th>
              <th scope="col" className="py-3 px-6">Time</th>
              <th scope="col" className="py-3 px-6">Type</th>
              <th scope="col" className="py-3 px-6">Status</th>
              <th scope="col" className="py-3 px-6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.appointmentId} className="bg-white border-b text-xs text-center text-gray-600">
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">{appointment.appointmentId}</th>
                <td className="py-4 px-3">{appointment.username}</td>
                <td className="py-4 px-3">{appointment.patientNameOnPrescription}</td>
                <td className="py-4 px-3">{appointment.patientSymptoms}</td>
                <td className="py-4 px-3">{appointment.patientMobileNumber}</td>
                <td className="py-4 px-3">{appointment.paymentMethod}</td>
                <td className="py-4 px-3">{new Date(appointment.appointmentDate).toLocaleDateString()}</td>
                <td className="py-4 px-3">{appointment.appointmentTime}</td>
                <td className="py-4 px-3">{appointment.appointmentType}</td>
                <td className="py-4 px-3">
                <span className={
                  `${appointment.appointmentStatus === 'approved' ? 'text-green-600' :
                    appointment.appointmentStatus === 'pending' ? 'text-red-500' :
                    'text-gray-500'}`
                }>
                  {appointment.appointmentStatus.charAt(0).toUpperCase() + appointment.appointmentStatus.slice(1)}
                </span>
              </td>
                <td className="py-4 px-6">
                  <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  onClick={() => handleAction(appointment.appointmentId)}>Actions</button>
                  {/* Additional actions here */}
                  {isActionModalVisible && (
                      <ActionModal
                        onClose={() => setIsActionModalVisible(false)}
                        onApprove={() => handleApprove()}
                        onPostpone={() => handlePostponeSubmit()}
                        feedback={actionFeedback}
                      />
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>) : (
          // Displayed when no appointments are present
          <div className="text-center py-8">
            <p className='text-gray-500 font-Poppins'>No postponed appointments found.</p>
          </div>
        )}


        {/* Pagination */}
        <nav aria-label="Page navigation example" className='flex justify-center mt-10'>
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <button onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700">Previous</button>
            </li>
            {pageNumbers.map(number => (
              <li key={number}>
                <button onClick={() => handlePageClick(number)} className={`px-3 h-8 leading-tight ${currentPage === number ? 'text-blue-600' : 'text-gray-500'} bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}>{number}</button>
              </li>
            ))}
            <li>
              <button onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700">Next</button>
            </li>
          </ul>
        </nav>


        <div>
          {/* Your existing UI elements */}
          <PostponeForm
            isOpen={isPostponeFormVisible}
            onClose={() => setIsPostponeFormVisible(false)}
            onSubmit={handlePostponeSubmit}
            initialDate={new Date().toISOString().slice(0, 10)}
            feedback={actionFeedback}
          />
        </div>

      </div>
    );
  };
export default PostponedAppointments

