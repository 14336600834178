import React from 'react'
import NavBar from '../../components/DoctorNavBar';
import Tally from '../../components/doctorDashboard/Tally';
import AppointmentsChart from '../../components/doctorDashboard/AppointmentsChart';
import RevenueChart from '../../components/doctorDashboard/RevenueChart';



const DoctorDashboard = () => {
  return (
    <div>

      <NavBar />


      <div className=' mt-24 lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:flex-row flex-col w-full border-b'>
        <Tally/>
      </div>

      <div className=' mt-10 lg:pl-72 w-full flex lg:px-0 px-3 mb-10 justify-center items-center'>
        <div className='max-w-7xl w-full flex lg:flex-row flex-col justify-center items-center gap-5'>
          <AppointmentsChart/>
          <RevenueChart/>
        </div>
      </div>

    </div>
  )
}

export default DoctorDashboard

