import React, { useState , useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api } from '../../utils/Api';


const ForgottenPassword = ({ setShowForgotPassword }) => {
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [showResendOtp, setShowResendOtp] = useState(false);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setShowResendOtp(true);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const extractErrorMessage = (error) => {
    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
      return error.response.data.errors[0].msg;
    }
    return 'Something went wrong, please try again.';
  };

  const handleSendOtp = async (values, actions) => {
    setIsSubmitting(true);
    try {
      await api.post('/auth/forgot-password', { phoneNumber: values.phoneNumber });
      setPhoneNumber(values.phoneNumber);
      setStep(2);
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      actions.setErrors({ phoneNumber: errorMsg });
    }
    setIsSubmitting(false);
  };

  const handleVerifyOtp = async (values, actions) => {
    setIsSubmitting(true);
    try {
      await api.post('/auth/verify-forgot-password-otp', { token: values.otp });
      setOtp(values.otp);
      setStep(3);
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      actions.setErrors({ otp: errorMsg });
    }
    setIsSubmitting(false);
  };

  const handleResetPassword = async (values, actions) => {
    const { newPassword, confirmNewPassword } = values;
    setIsSubmitting(true);
    try {
      await api.put('/auth/reset-password', {
        token: otp,
        newPassword,
        confirmNewPassword,
      });
      setShowForgotPassword(false);
    } catch (error) {
      const errorMsg = extractErrorMessage(error);
      actions.setErrors({ newPassword: errorMsg });
    }
    setIsSubmitting(false);
  };

  const getValidationSchema = () => {
    switch (step) {
      case 1:
        return Yup.object().shape({
          phoneNumber: Yup.string().required('Phone number is required'),
        });
      case 2:
        return Yup.object().shape({
          otp: Yup.string().required('OTP is required'),
        });
      case 3:
        return Yup.object().shape({
          newPassword: Yup.string()
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .required('Password is required'),
          confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
        });
      default:
        return null;
    }
  };

  const handleResendOtp = async () => {
    setIsSubmitting(true);
    try {
      await api.post('/auth/forgot-password', { phoneNumber });
      setCountdown(60);
      setShowResendOtp(false);
    } catch (error) {
      console.error('Failed to resend OTP', error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="mb-4 text-lg font-bold text-gray-700">Forgot Password</h2>
        <Formik
          initialValues={{
            phoneNumber: '',
            otp: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          validationSchema={getValidationSchema}
          onSubmit={(values, actions) => {
            if (step === 1) {
              const country_code = '+232';
              const mobileNum = values.phoneNumber.slice(-8);
              values.phoneNumber = country_code + mobileNum;
              handleSendOtp(values, actions);
            }
            if (step === 2) handleVerifyOtp(values, actions);
            if (step === 3) handleResetPassword(values, actions);
          }}
        >
          {() => (
            <Form>
              {step === 1 && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-bold mb-2"
                    htmlFor="phoneNumber">
                      Phone Number
                    </label>
                    <Field
                      name="phoneNumber"
                      type="text"
                      className="appearance-none border rounded border-blue-300
                      w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <button
                    type="submit"
                    className={`py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins
                    ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Submitting...' : 'Send OTP'}
                  </button>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-bold mb-2" htmlFor="otp">
                      OTP
                    </label>
                    <p className="text-gray-500 text-xs mb-2">Enter the OTP sent to {phoneNumber}</p>

                    {!showResendOtp ? (
                      <p className="text-gray-500 text-xs mb-2">Resend OTP in {countdown} seconds</p>
                    ) : (
                      <button
                        type="button"
                        onClick={handleResendOtp}
                        className="text-blue-500 text-xs mb-2"
                      >
                        Resend OTP
                      </button>
                    )}

                    <Field
                      name="otp"
                      maxLength={6}
                      type="text"
                      pattern="\d*"
                      className="appearance-none border rounded border-blue-300
                      w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />

                    <ErrorMessage name="otp" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <button
                    type="submit"
                    className={`py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins
                    ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                  </button>
                </>
              )}

              {step === 3 && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-bold mb-2" htmlFor="newPassword">
                      New Password
                    </label>
                    <Field
                      name="newPassword"
                      type="password"
                      className="appearance-none border rounded border-blue-300
                      w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage name="newPassword" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-bold mb-2" htmlFor="confirmNewPassword">
                      Confirm New Password
                    </label>
                    <Field
                      name="confirmNewPassword"
                      type="password"
                      className="appearance-none border rounded border-blue-300
                      w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage name="confirmNewPassword" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <button
                    type="submit"
                    className={`py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins
                    ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Resetting...' : 'Reset Password'}
                  </button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <button
          onClick={() => setShowForgotPassword(false)}
          className="mt-4 w-full py-2 bg-orange-100 text-orange-500 rounded hover:bg-orange-200 hover:text-orange-600"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ForgottenPassword;
