import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import payment_failure_lottie from '../lottiefiles/payment_failure.json';

const PaymentFailurePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const timeout = setTimeout(() => {
        navigate('/userDashboard'); // Redirect to /userDashboard after 5 seconds
      }, 4000);

      return () => clearTimeout(timeout);
    }, [navigate]);

    return (
      <div className='bg-gray-100 w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col justify-center items-center max-w-7xl'>
          <h2 className='mb-5 mt-5 text-xl text-red-500 font-Poppins font-bold'>Payment unsuccessful </h2>
          <p className="text-gray-500 text-center font-Poppins">Your payment failed ❗️❗️❗️❗️❗️, please try again  </p>
          <div className='w-1/2 flex justify-center items-center'>
            <Lottie animationData={payment_failure_lottie} width={300} height={300} loop={true} />
          </div>
        </div>
      </div>
    );
}

export default PaymentFailurePage
