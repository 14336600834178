import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';

const AvailableSpecialty = ({ setFieldValue }) => {
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch available specialties from the API
    setIsLoading(true); // Set loading to true at the start of the API call
    api.get('/specialties')
      .then((response) => {
        if (response.data.status === 0) {
          // Handle error if needed
          setIsLoading(false); // Ensure to set loading to false on API error
        } else {
          setSpecialties(response.data.data);
          setIsLoading(false); // Ensure to set loading to false on API error
        }
      })
      .catch((error) => {
        // Handle error if the API request fails
        setIsLoading(false); // Ensure to set loading to false on API error
      });
  }, []);

  const openPopup = (specialty) => {
    setSelectedSpecialty(specialty);
  };

  const closePopup = () => {
    setSelectedSpecialty(null);
  };

  const viewSpecialty = () => {
    if (selectedSpecialty) {
      const specialtyId = selectedSpecialty.specialtyId;
      navigate(`/viewSpecialty/${specialtyId}`);
      closePopup();
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
      slidesToSlide: 1,
      justifyContent: 'center',
    },
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
            h-32 w-32 flex justify-center items-center">
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-7xl">
      <label htmlFor="specialty" className="text-lg font-medium text-gray-700 font-Poppins">All available specialties</label>
      <p className='text-gray-600 lg'>See all doctors for a particular specialty by clicking on the specialty </p>
      <div className='h-4'> </div>
      <Carousel
          responsive={responsive}
          infinite
          draggable
          swipeable
          centerMode={false}
          autoPlay={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={['tablet', 'mobile']}
          className='w-full z-0 '
        >
        {specialties.map((specialty) => (
          <div key={specialty.id}
          className="border border-gray-200 hover:border-blue-500 flex flex-col justify-center items-center w-44 rounded-md p-3
          cursor-pointer hover:shadow-md transition duration-300 ease-in-out"
          onClick={() => openPopup(specialty)}>
            <img src={specialty.imageUrl} alt={specialty.specialtyName} className="w-16 h-16 object-cover" />
            <p className="text-xs text-center text-gray-700 font-Poppins mt-2">{specialty.specialtyName.slice(0,20)}</p>
          </div>
        ))}
    </Carousel>
    {selectedSpecialty && (
      <div className="fixed inset-0 flex items-center justify-center lg:pl-72 bg-gray-800 bg-opacity-50">
        <div className="bg-white p-8 max-w-md rounded-md shadow-md flex flex-col justify-center items-center">
          <div className='w-full'>
            <span className="text-red-500 font-bold text-xl hover:cursor-pointer mb-4" onClick={closePopup}>&times;</span>
          </div>
          <span className="absolute top-0 right-0 cursor-pointer p-2" onClick={closePopup}>&times;</span>
          <img src={selectedSpecialty.imageUrl} alt={selectedSpecialty.specialtyName} className="w-40 h-40 object-cover"/>
          <h2 className="text-lg font-semibold mb-3">{selectedSpecialty.specialtyName}</h2>
          <p className='text-sm text-gray-700 mb-3'>{selectedSpecialty.description}</p>
          <button className="text-blue-600 hover:underline" onClick={viewSpecialty}>
            See all doctors for {selectedSpecialty.specialtyName}</button>
        </div>
      </div>
    )}
    </div>
  );
};

export default AvailableSpecialty;
