import React, { useState } from 'react';
import UserNavBar from '../../components/UserNavBar';
import DoctorAppointmentModal from '../../components/userDashboard/DoctorAppointmentModal';
import DoctorAppointmentHistory from '../../components/userDashboard/DoctorAppointmentHistory';
import Lottie from 'lottie-react';
import appointment from '../../lottiefiles/appointment.json';

const Appointments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className='bg-white overflow-x-hidden'>
      <UserNavBar />
      <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-blue-100 pt-16'>
        <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
          <div className='sm:w-1/2 flex justify-center items-center'>
            <Lottie className='w-full' animationData={appointment} loop={true} />
          </div>
          <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
            <h1 className='text-xl text-center font-bold font-Poppins'>Book an appointment with a doctor</h1>
            <p className='text-center font-Poppins'>Book an appointment with a doctor to get medical advice, prescriptions, and more</p>
            {/* Button to open the modal */}
            <button onClick={toggleModal} className="text-blue-500 hover:text-blue-400 text-lg py-2 px-4 rounded font-Poppins mt-5">Book now</button>
            {/* DoctorAppointmentModal */}
            {isModalOpen && <DoctorAppointmentModal onClose={toggleModal} />}
          </div>
        </div>
      </div>
      <div className='lg:pl-72 mt-14 mb-10 lg:px-10 px-3 w-full flex justify-center'>
        <DoctorAppointmentHistory />
      </div>
    </div>
  );
};

export default Appointments;
