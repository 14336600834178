import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
  SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, DOCTOR_SIGNUP_FAILURE,
  DOCTOR_SIGNUP_REQUEST, DOCTOR_SIGNUP_SUCCESS, VERIFY_OTP, OTP_SUCCESS, OTP_FAILURE,
  SUBMIT_DOCTOR_DETAIL_REQUEST, SUBMIT_DOCTOR_DETAIL_SUCCESS, SUBMIT_DOCTOR_DETAIL_FAILURE,
  SUBMIT_USER_DETAIL_FAILURE, SUBMIT_USER_DETAIL_REQUEST, SUBMIT_USER_DETAIL_SUCCESS,
  SUBMIT_USER_MEDICAL_DETAILS_FAILURE, SUBMIT_USER_MEDICAL_DETAILS_REQUEST, SUBMIT_USER_MEDICAL_DETAILS_SUCCESS,
  FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE, FETCH_USER_MEDICAL_DETAILS_SUCCESS,
  FETCH_USER_MEDICAL_DETAILS_FAILURE, FETCH_USER_MEDICAL_DETAILS_REQUEST, DOCTOR_APPOINTMENT_HISTORY_REQUEST,
  DOCTOR_APPOINTMENT_HISTORY_SUCCESS, DOCTOR_APPOINTMENT_HISTORY_FAILURE, UPLOAD_DOCUMENT, UPDATE_DOCUMENT,
  DELETE_DOCUMENT, FETCH_DOCTOR_PROFILE_REQUEST, FETCH_DOCTOR_PROFILE_SUCCESS, FETCH_DOCTOR_PROFILE_FAILURE,
} from "./actionsTypes";

// Initial state for userLoginReducer
const initialUserLoginState = {
  token: '',
  loading: false,
  error: null,
};
// user login reducer
export const userLoginReducer = (state = initialUserLoginState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Initial state for signupReducer
const initialSignupState = {
  token: '',
  loading: false,
  error: null,
};
// user signup reducer
export const userSignupReducer = (state = initialSignupState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Initial state for doctor signupReducer
const initialDoctorSignupState = {
  token: '',
  loading: false,
  error: null,
};
// user signup reducer
export const doctorSignupReducer = (state = initialDoctorSignupState, action) => {
  switch (action.type) {
    case DOCTOR_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOCTOR_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case DOCTOR_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Initial state for otpReducer
const initialState = {
  otpVerified: false,
  loading: false,
  error: null,
};
// otp reducer
export const otpReducer = (state = initialState, action) => {
  switch (action.type) {
      case VERIFY_OTP:
          return { ...state, loading: true };
      case OTP_SUCCESS:
          return { ...state, loading: false, otpVerified: true };
      case OTP_FAILURE:
          return { ...state, loading: false, error: action.payload };
      default:
          return state;
  }
};

// doctor info setup reducer
const DoctorInfoInitialState = {
  specializations: [],
  cities: [],
};
// doctor info setup reducer
export const doctorInfoReducer = (state = DoctorInfoInitialState, action) => {
  switch (action.type) {
    case 'FETCH_SPECIALIZATIONS_SUCCESS':
      return { ...state, specializations: action.payload };
    case 'FETCH_CITIES_SUCCESS':
      return { ...state, cities: action.payload };
    // Handle other actions related to form submission and state management
    default:
      return state;
  }
};

const doctorSubmitFormInitialState = {
  isSubmitting: false,
  error: null,
};

export const doctorSubmitFormReducer = (state = doctorSubmitFormInitialState, action) => {
  switch (action.type) {
    case SUBMIT_DOCTOR_DETAIL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };
    case SUBMIT_DOCTOR_DETAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SUBMIT_DOCTOR_DETAIL_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Submit user detail actions
const userInfoInitialState = {
  isSubmitting: false,
  error: null,
};
// Submit user detail actions
export const userInfoReducer = (state = userInfoInitialState, action) => {
  switch (action.type) {
    case SUBMIT_USER_DETAIL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };
    case SUBMIT_USER_DETAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SUBMIT_USER_DETAIL_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const userMedicalDetailsInitialSate = {
  isSubmitting: false,
  error: null,
};

export const userMedicalDetailsReducer = (state = userMedicalDetailsInitialSate, action) => {
  switch (action.type) {
    case SUBMIT_USER_MEDICAL_DETAILS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };
    case SUBMIT_USER_MEDICAL_DETAILS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SUBMIT_USER_MEDICAL_DETAILS_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


const userProfileInitialState = {
  data: {},
  loading: false,
  error: null,
};

export const userProfileReducer = (state = userProfileInitialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};




const doctorProfileInitialState = {
  data: {},
  loading: false,
  error: null,
};

export const doctorProfileReducer = (state = doctorProfileInitialState, action) => {
  switch (action.type) {
    case FETCH_DOCTOR_PROFILE_REQUEST:
        return { ...state, loading: true };
    case FETCH_DOCTOR_PROFILE_SUCCESS:
        return { ...state, loading: false, data: action.payload };
    case FETCH_DOCTOR_PROFILE_FAILURE:
        return { ...state, loading: false, error: action.payload };
    default:
        return state;
}
};


const fetchUserMedicalDetailsInitialState = {
  data:[],
  loading: false,
  error: null,
};

export const fetchUserMedicalDetailReducer = (state = fetchUserMedicalDetailsInitialState, action) => {
  switch (action.type) {
    case FETCH_USER_MEDICAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_MEDICAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_USER_MEDICAL_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


const doctorAppointmentHistoryInitialSate = {
  isSubmitting: false,
  loading: false,
  error: null,
  data: [],
};

export const doctorAppointmentHistoryReducer = (state = doctorAppointmentHistoryInitialSate, action) => {
  switch (action.type) {
    case DOCTOR_APPOINTMENT_HISTORY_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        loading: true,
        error: null,
      };
    case DOCTOR_APPOINTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        loading: false,
        data: action.payload,
      };
    case DOCTOR_APPOINTMENT_HISTORY_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


const medicalDocumentInitialState = {
  documents: []
};

export const uploadUserMedicalDocumentReducer = (state = medicalDocumentInitialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT:
        return {
            ...state,
            documents: [...state.documents, action.payload]
        };
    case UPDATE_DOCUMENT:
        return {
            ...state,
            documents: state.documents.map(doc =>
                doc.documentId === action.payload.documentId ? { ...doc, ...action.payload.updatedDocument } : doc
            )
        };
    case DELETE_DOCUMENT:
        return {
            ...state,
            documents: state.documents.filter(doc => doc.documentId !== action.payload)
        };
    default:
        return state;
  }
};

// Initial state for doctorCouncilDocumentInitialState
const doctorCouncilDocumentInitialState = {
  documents: []
};
// doctor council document reducer
export const uploadDoctorCouncilDocumentReducer = (state = doctorCouncilDocumentInitialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT:
        return {
            ...state,
            documents: [...state.documents, action.payload]
        };
    default:
        return state;
  }
};





