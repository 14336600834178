import React from 'react'
import Lottie from 'lottie-react';
import family_lottie from '../../../lottiefiles/family_lottie.json'
import { Link } from 'react-router-dom';

const KenecareFamily = () => {
  return (
    <div className='flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-gray-50 pt-20 shadow-lg rounded-lg max-w-7xl'
    data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
    >
        <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-5 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl mb-5'>
          <div className='sm:w-2/3 flex justify-center items-center'>
            <Lottie className='w-full' animationData={family_lottie} loop={true} />
          </div>
          <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='mb-5 mt-5 text-lg text-center text-blue-500 font-Poppins font-bold'>Kenecare Family</h1>
              <p className='text-center font-Poppins'>
                Add a family member to your Kenecare account, to help manage their health records and appointments
              </p>

              <Link to='/addFamilyMember'
              className=' text-blue-500 hover:text-blue-400 px-4 py-2 rounded-lg mt-2 font-Poppins mb-'
              >
                Add Family Member +
            </Link>
            </div>
        </div>
      </div>
  )
}

export default KenecareFamily
