import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../../redux/actions";
import { api } from "../../../utils/Api";

const UserInfo = ({ userProfile, fetchUserProfile }) => {
    const [medicalDetails, setMedicalDetails] = useState({
        firstname: '',
        middlename: '',
        lastname: '',
        gender: '',
        dateOfBirth: '',
      });

      const [feedback, setFeedback] = useState({
        message: '',
        type: '' // 'success' or 'error'
    });

      useEffect(() => {
        fetchUserProfile();
      }, [fetchUserProfile]);

      useEffect(() => {
        if (!userProfile.loading && !userProfile.error && userProfile) {
          setMedicalDetails(userProfile.data);
        }
      }, [userProfile]);

      const handleChange = (e) => {
        const { name, value } = e.target;
        setMedicalDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
            console.log('====================================');
            console.log(medicalDetails);
            console.log('====================================');
        try {
            const response = await api.put('/patients/profile', medicalDetails);
            setFeedback({ message: 'Profile updated successfully!', type: 'success' });
            console.log('Update response:', response.data);
        } catch (error) {
            const message = error.response?.data?.message || error.message;
        setFeedback({ message: 'Failed to update profile: ' + message, type: 'error' });
        console.log('Error updating profile:', error.response || error);
        }
    };


    if (userProfile.error) {
        return <p>Error: {userProfile.error}</p>;
    }


      if (userProfile.loading) {
        return <div className="w-full flex justify-center items-center" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
           <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
            h-32 w-32 flex justify-center items-center"
            >
              <p className="text-gray-500 ml-4 text-sm">Loading...</p>
            </div>
          </div>;
      }

      if (userProfile.error) {
        return <p>Error: {userProfile.error}</p>;
      }

      return (
        <div className="p-4 w-full bg-gray-50 rounded-lg shadow-lg max-w-7xl"
        data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
        >
            {/* show error message here */}
          <p className='mb-5 mt-5 text-lg text-center text-blue-500 font-Poppins font-bold'>Update Profile Details</p>
          {feedback.message && (
                    <p className={`text-${feedback.type === 'success' ? 'green' : 'red'}-500`}>
                        {feedback.message}
                    </p>
                )}
          <form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-3 gap-2 w-full text-gray-500 text-sm font-Poppins">
              <div>
                <label htmlFor="firstName">First Name:</label>
                <input type="text" id="firstName" name="firstName" value={medicalDetails.firstName} onChange={handleChange} required
                 className="appearance-none border border-blue-300 rounded w-full py-2 px-3
                 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
              <div>
                <label htmlFor="middleName">Middle Name:</label>
                <input type="text" id="middleName" name="middleName" value={medicalDetails.middleName} onChange={handleChange} required
                 className="appearance-none border border-blue-300 rounded w-full py-2 px-3
                 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
              </div>
              <div>
                <label htmlFor="lastName">Last Name:</label>
                <input type="text" id="lastName" name="lastName" value={medicalDetails.lastName} onChange={handleChange} required
                 className="appearance-none border border-blue-300 rounded w-full py-2 px-3
                 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
              </div>

              <div>
                    <label htmlFor="gender">Gender:</label>
                    <select id="gender" name="gender" value={medicalDetails.gender} onChange={handleChange} required
                        className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>

              <div>
                <label htmlFor="dob">Date Of Birth:</label>
                <input type="date" id="dob" name="dob" value={medicalDetails.dob} onChange={handleChange} required
                 className="appearance-none border border-blue-300 rounded w-full py-2 px-3
                 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
              </div>

            </div>
            <button type="submit"
            class="py-3 px-10 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
            rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10
            focus:ring-4 focus:ring-gray-200 font-Poppins mt-4"
            >Update Profile Details</button>
          </form>
        </div>
      );
    };

    const mapStateToProps = (state) => ({
        userProfile: state.userProfile
    });

    const mapDispatchToProps = (dispatch) => ({
        fetchUserProfile: () => dispatch(fetchUserProfile()), // Replace with your actual action creator
    });


export default connect(mapStateToProps, mapDispatchToProps) (UserInfo);
