import React, { useState, useEffect } from "react";
import { api } from "../../utils/Api";

const Tally = () => {

const [pendingAppointmentsCount, setPendingAppointmentsCount] = useState(0);
const [postponedAppointmentsCount, setPostponedAppointmentsCount] = useState(0);
const [todayMeetingsCount, setTodayMeetingsCount] = useState(0);
const currentPage = useState(1);

useEffect(() => {
  const fetchAppointments = async () => {
    try {
      const response = await api.get('/doctors/appointments?page=1&limit=20');
      const appointments = response.data.data;
      const pendingCount = appointments.filter(appointment => appointment.appointmentStatus === 'pending').length;
      const postponedCount = appointments.filter(appointment => appointment.appointmentStatus === 'postponed').length;
      setPendingAppointmentsCount(pendingCount);
      setPostponedAppointmentsCount(postponedCount);

      // Calculate today's meetings count
      const today = new Date().toLocaleDateString();
      const todayMeetings = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.appointmentDate).toLocaleDateString();
        return appointment.appointmentStatus === 'approved' && appointmentDate === today;
      }).length;
      setTodayMeetingsCount(todayMeetings);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  fetchAppointments(currentPage);
    const intervalId = setInterval(fetchAppointments, 5000, currentPage);
    return () => clearInterval(intervalId);
}, [currentPage]);


  return (
    <div className="flex flex-col sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4  pb-7 max-w-7xl">
      <div className="bg-gradient-to-r from-green-800 to-yellow-400 rounded-lg shadow px-10 py-16 flex flex-col items-center justify-center">
        <h2 className="text-lg font-semibold mb-2 text-white">Pending Appointments</h2>
        <svg class="w-10 h-10 text-white my-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M5 5c.6 0 1-.4 1-1a1 1 0 1 1 2 0c0 .6.4 1 1 1h1c.6 0 1-.4 1-1a1 1 0 1 1 2 0c0 .6.4 1 1 1h1c.6 0 1-.4 1-1a1 1 0 1 1 2 0c0 .6.4 1 1 1a2 2 0 0 1 2 2v1c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V7c0-1.1.9-2 2-2ZM3 19v-7c0-.6.4-1 1-1h16c.6 0 1 .4 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6-6c0-.6-.4-1-1-1a1 1 0 1 0 0 2c.6 0 1-.4 1-1Zm2 0a1 1 0 1 1 2 0c0 .6-.4 1-1 1a1 1 0 0 1-1-1Zm6 0c0-.6-.4-1-1-1a1 1 0 1 0 0 2c.6 0 1-.4 1-1ZM7 17a1 1 0 1 1 2 0c0 .6-.4 1-1 1a1 1 0 0 1-1-1Zm6 0c0-.6-.4-1-1-1a1 1 0 1 0 0 2c.6 0 1-.4 1-1Zm2 0a1 1 0 1 1 2 0c0 .6-.4 1-1 1a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
        </svg>
        <p className="text-xl text-center font-Poppins font-bold text-white">{pendingAppointmentsCount}</p>
      </div>

      <div className="bg-gradient-to-r from-red-400 to-pink-600  rounded-lg shadow px-10 py-16 flex flex-col items-center justify-center">
        <h2 className="text-lg font-semibold mb-2 text-white">Postponed Appointments</h2>
        <svg class="w-10 h-10 text-white my-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 8v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0Zm12 7h-1v1a1 1 0 0 1-2 0v-1H8a1 1 0 0 1 0-2h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 0 1 0 2Z"/>
        </svg>

        <p className="text-xl text-center font-Poppins font-bold text-white">{postponedAppointmentsCount}</p>
      </div>

      <div className="bg-gradient-to-r from-pink-500 to-yellow-400 rounded-lg shadow px-10 py-16 flex flex-col items-center justify-center">
        <h2 className="text-lg font-semibold mb-2 text-white">Revenue Generated</h2>
        <svg class="w-10 h-10 my-2 text-white"
            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v15c0 .6.4 1 1 1h15M8 16l2.5-5.5 3 3L17.3 7 20 9.7"/>
        </svg>
        <p className="text-xl text-center font-Poppins font-bold text-white">NLe 2000</p>
      </div>

      <div className="bg-gradient-to-r from-purple-900 to-cyan-400  rounded-lg shadow px-10 py-16 flex flex-col items-center justify-center"

      >
        <h2 className="text-lg font-semibold mb-2 text-white">Today Meetings</h2>
        <svg class="w-10 h-10 text-white my-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V7Zm2 9.4 4.7 1.5A1 1 0 0 0 22 17V7a1 1 0 0 0-1.3-1L16 7.7v8.8Z" clip-rule="evenodd"/>
        </svg>

        <p className="text-xl text-center font-Poppins font-bold text-white">{todayMeetingsCount}</p>
      </div>

    </div>
  );
};

export default Tally;
