import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { submitUserMedicalDetails } from '../../redux/actions';
import Lottie from 'lottie-react';
import { Link , useNavigate} from 'react-router-dom';
import user_medicalDetail_lottie from '../../lottiefiles/user_medical_detail.json'

const validationSchema = Yup.object().shape({
  height: Yup.string(),
  weight: Yup.string(),
  allergies: Yup.string(),
  isDisabled: Yup.boolean(),
  disabilityDesc: Yup.string(),
  useTobacco: Yup.boolean(),
  tobaccoIntakeFreq: Yup.string(),
  alcoholIntake: Yup.boolean(),
  alcoholIntakeFreq: Yup.string(),
  caffineIntake: Yup.boolean(),
  caffineIntakeFreq: Yup.string(),
});

const UserMedicalDetailsForm = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
    // Convert boolean values to strings as needed
    values.isDisabled = values.isDisabled.toString();
    values.useTobacco = values.useTobacco.toString();
    values.alcoholIntake = values.alcoholIntake.toString();
    values.caffineIntake = values.caffineIntake.toString();

    await dispatch(submitUserMedicalDetails(values));
      setSuccessMessage('Medical details submitted successfully.');
      setErrorMessage('');
      resetForm();
      navigate('/uploadProfilePicture');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle specific error status (e.g., 400 Bad Request)
        setErrorMessage('Bad Request: Please check your input data.');
      } else {
        // Handle other errors
        setErrorMessage('An error occurred while submitting user details.');
      }
      setSuccessMessage('');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let messageTimeout;

    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  return (
    <Formik
      initialValues={{
        height: '',
        weight: '',
        allergies: '',
        isDisabled: false,
        disabilityDesc: '',
        useTobacco: false,
        tobaccoIntakeFreq: '',
        alcoholIntake: false,
        alcoholIntakeFreq: '',
        caffineIntake: false,
        caffineIntakeFreq: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
      <div
      className="w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3"
    >

      <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Verify your OTP </h2>
            <p className="text-gray-500 text-center font-Poppins mb-2">Enter your medical details on the below form and submit</p>
            <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>

              <div className='w-1/3'>
                 <Lottie animationData={user_medicalDetail_lottie} width={300} height={300} loop={true} />
              </div>
          <div className="bg-white px-6 rounded-lg shadow py-14 w-full sm:w-2/3 max-w-5xl">
            <div className='w-full flex-col sm:grid grid-cols-2 gap-4'>
            <h2 className="text-2xl font-bold text-center col-span-2 mb-4">Your medical details</h2>

            {successMessage && (
                  <div className="bg-green-200 p-2 text-green-700 mb-4 rounded col-span-2">
                    {successMessage}
                  </div>
                )}

                {errorMessage && (
                  <div className="bg-red-200 p-2 text-red-700 mb-4 rounded col-span-2">
                    {errorMessage}
                  </div>
                )}
            <div className="flex flex-col">
              <label htmlFor="height">Height</label>
              <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="height"
              id="height" placeholder="e.g., 1.2cm" />
              <ErrorMessage name="height" component="div" className="text-red-500" />
            </div>

            <div className="flex flex-col">
              <label htmlFor="weight">Weight</label>
              <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="weight"
                id="weight" placeholder="e.g., 40kg" />
              <ErrorMessage name="weight" component="div" className="text-red-500" />
            </div>

            <div className="flex flex-col">
              <label htmlFor="allergies">Allergies</label>
              <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="allergies"
                id="allergies" placeholder="Cauliflower, paracetamol" />
              <ErrorMessage name="allergies" component="div" className="text-red-500" />
            </div>

            {/* Other fields here with appropriate conversion */}

            <div className="flex flex-col">
            <label>Are you disabled?</label>
            <div className="flex space-x-4">
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="isDisabled" value="true" />
                Yes
              </label>
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="isDisabled" value="false" />
                No
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="disabilityDesc">Disability Description</label>
            <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="disabilityDesc"
              id="disabilityDesc" placeholder="Disability Description"  />
            <ErrorMessage name="disabilityDesc" component="div" className="text-red-500" />
          </div>

          <div className="flex flex-col">
            <label>Do you use tobacco?</label>
            <div className="flex space-x-4">
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="useTobacco" value="true" />
                Yes
              </label>
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="useTobacco" value="false" />
                No
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="tobaccoIntakeFreq">Tobacco Intake Frequency</label>
            <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="tobaccoIntakeFreq"
              id="tobaccoIntakeFreq" placeholder="Tobacco Intake Frequency"/>
            <ErrorMessage name="tobaccoIntakeFreq" component="div" className="text-red-500" />
          </div>

          <div className="flex flex-col">
            <label>Do you consume alcohol?</label>
            <div className="flex space-x-4">
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="alcoholIntake" value="true" />
                Yes
              </label>
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="alcoholIntake" value="false" />
                No
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="alcoholIntakeFreq">Alcohol Intake Frequency</label>
            <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="alcoholIntakeFreq"
            id="alcoholIntakeFreq" placeholder="Alcohol Intake Frequency" />
            <ErrorMessage name="alcoholIntakeFreq" component="div" className="text-red-500" />
          </div>

          <div className="flex flex-col">
            <label>Do you consume caffeine?</label>
            <div className="flex space-x-4">
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="caffineIntake" value="true" />
                Yes
              </label>
              <label>
                <Field className="bg-gray-50 border border-blue-400" type="radio" name="caffineIntake" value="false" />
                No
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="caffineIntakeFreq">Caffeine Intake Frequency</label>
            <Field className="bg-gray-50 border border-blue-400 rounded px-2" type="text" name="caffineIntakeFreq"
            id="caffineIntakeFreq" placeholder="Caffeine Intake Frequency"/>
            <ErrorMessage name="caffineIntakeFreq" component="div" className="text-red-500" />
          </div>
          </div>
            <div className='sm:w-1/2 w-full flex justify-between'>
              <button type="submit"
              disabled={isLoading}
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
              rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
              focus:ring-gray-200 w-full mt-5 font-Poppins"
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
              <Link to='/userDashboard' className="text-gray-500 hover:text-blue-500 rounded px-4 py-2 mt-4 ml-4">
                Skip
              </Link>
            </div>
          </div>

        </div>
      </div>
      </Form>
    </Formik>
  );
};

export default UserMedicalDetailsForm;
