import React from 'react'

const UserPostponedMessage = ({setShowProspondMessage}) => {
  return (
    <div className="sm:pl-72 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
        <div className='h-[20rem] w-[20rem] bg-white rounded'>
        <button
          onClick={() => setShowProspondMessage(false)}
          className="mt-3 ml-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
        >
          X
        </button>
            <div className="flex justify-center items-center h-full">
                <p> User postponed message </p>
            </div>
        </div>
    </div>
  )
}

export default UserPostponedMessage
