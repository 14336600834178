import React from 'react';
import DoctorAppointmentForm from './DoctorAppointmentForm'; // Ensure the file path is correct

const DoctorAppointmentModal = ({ onClose }) => {
  return (
    <div className="sm:pl-72 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="relative lg:w-1/2 w-full ">
        <DoctorAppointmentForm onClose={onClose} />
      </div>
    </div>
  );
};

export default DoctorAppointmentModal;
