// user login actions
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// user signup actions
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// doctor signup actions
export const DOCTOR_SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const DOCTOR_SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const DOCTOR_SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// user logout actions
export const VERIFY_OTP = 'VERIFY_OTP';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAILURE = 'OTP_FAILURE';

// Submit doctor detail actions
export const SUBMIT_DOCTOR_DETAIL_REQUEST = 'SUBMIT_DOCTOR_DETAIL_REQUEST';
export const SUBMIT_DOCTOR_DETAIL_SUCCESS = 'SUBMIT_DOCTOR_DETAIL_SUCCESS';
export const SUBMIT_DOCTOR_DETAIL_FAILURE = 'SUBMIT_DOCTOR_DETAIL_FAILURE';

// Submit user detail actions
export const SUBMIT_USER_DETAIL_REQUEST = 'SUBMIT_USER_DETAIL_REQUEST';
export const SUBMIT_USER_DETAIL_SUCCESS = 'SUBMIT_USER_DETAIL_SUCCESS';
export const SUBMIT_USER_DETAIL_FAILURE = 'SUBMIT_USER_DETAIL_FAILURE';

// fetch user medical details actions
export const SUBMIT_USER_MEDICAL_DETAILS_REQUEST = 'SUBMIT_USER_MEDICAL_DETAILS_REQUEST';
export const SUBMIT_USER_MEDICAL_DETAILS_SUCCESS = 'SUBMIT_USER_MEDICAL_DETAILS_SUCCESS';
export const SUBMIT_USER_MEDICAL_DETAILS_FAILURE = 'SUBMIT_USER_MEDICAL_DETAILS_FAILURE';

// fetch user profile details actions
export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

// fetch doctor profile details actions
export const FETCH_DOCTOR_PROFILE_REQUEST = "FETCH_DOCTOR_PROFILE_REQUEST";
export const FETCH_DOCTOR_PROFILE_SUCCESS = "FETCH_DOCTOR_PROFILE_SUCCESS";
export const FETCH_DOCTOR_PROFILE_FAILURE = "FETCH_DOCTOR_PROFILE_FAILURE";

// fetch user medical history actions
export const FETCH_USER_MEDICAL_DETAILS_REQUEST = "ETCH_USER_MEDICAL_DETAILS_REQUEST";
export const FETCH_USER_MEDICAL_DETAILS_SUCCESS = "FETCH_USER_MEDICAL_DETAILS_SUCCESS";
export const FETCH_USER_MEDICAL_DETAILS_FAILURE = "FETCH_USER_MEDICAL_DETAILS_FAILURE";

// fetch user medical history actions
export const DOCTOR_APPOINTMENT_FORM_REQUEST = "DOCTOR_APPOINTMENT_FORM_REQUEST";
export const DOCTOR_APPOINTMENT_FORM_SUCCESS = "DOCTOR_APPOINTMENT_FORM_SUCCESS";
export const DOCTOR_APPOINTMENT_FORM_FAILURE = "DOCTOR_APPOINTMENT_FORM_FAILURE";

// fetch user medical history actions
export const DOCTOR_APPOINTMENT_HISTORY_REQUEST = "DOCTOR_APPOINTMENT_HISTORY_REQUEST";
export const DOCTOR_APPOINTMENT_HISTORY_SUCCESS = "DOCTOR_APPOINTMENT_HISTORY_SUCCESS";
export const DOCTOR_APPOINTMENT_HISTORY_FAILURE = "DOCTOR_APPOINTMENT_HISTORY_FAILURE";

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const RETRIEVE_DOCUMENTS = 'RETRIEVE_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const DOCTOR_COUNCIL_REGISTRATION_DOCUMENT = 'DOCTOR_COUNCIL_REGISTRATION_DOCUMENT';
