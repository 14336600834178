import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { api } from "../../../utils/Api";

const UpdateDoctorDetails = () => {
  const [cities, setFetchCities] = useState([]);
  const [specialties, setFetchSpecialties] = useState([]);

  const [doctorDetails, setDoctorDetails] = useState({
    mobileNumber: '',
    email: '',
    professionalSummary: '',
    specialization: '',
    qualifications: '',
    consultationFees: '',
    city: '',
    yearOfExperience: ''
  });



  useEffect(() => {
    api.get('/doctors/profile')
      .then(response => {
        const data = response.data.data;
        setDoctorDetails({
          mobileNumber: data.mobileNumber,
          email: data.email,
          professionalSummary: data.professionalSummary,
          specialization: data.specialization,
          qualifications: data.qualifications,
          consultationFees: data.consultationFees,
          city: data.city,
          yearOfExperience: data.yearOfExperience
        });
      })
      .catch(error => {
        console.error('Error fetching doctor profile:', error);
      });
    }, []);

  useEffect(() => {
    api.get('/cities')
      .then(response => {
        const cityNames = response.data.data.map(city => city.cityName);
        setFetchCities(cityNames);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  }, []);

  useEffect(() => {
    api.get('/specialties')
    .then((response) => {
      if (response.data.status === 0) {
          // Handle error if needed
      } else {
          // Set the city state with the data from the API
          setFetchSpecialties(response.data.data);
      }
  })
  .catch((error) => {
      // Handle error if the API request fails
  });
  }, []);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setDoctorDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch action to update doctor details using doctorDetails state
    console.log("Updated Doctor Details:", doctorDetails);
  };

  return (
    <div className="p-4 w-full bg-gray-50 rounded-lg shadow-lg max-w-7xl"
    data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
    >
      <p className='mb-5 mt-5 text-lg text-center text-blue-500 font-Poppins font-bold'>Update Doctor Details</p>
      <form onSubmit={handleSubmit}>
        <div className="grid sm:grid-cols-2 gap-2 w-full text-gray-500 text-sm font-Poppins">
          <div>
            <label htmlFor="mobileNumber">Mobile Number:</label>
            <input type="text" id="mobileNumber" name="mobileNumber" value={doctorDetails.mobileNumber} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={doctorDetails.email} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div>
            <label htmlFor="professionalSummary">Professional Summary:</label>
            <textarea id="professionalSummary" name="professionalSummary" value={doctorDetails.professionalSummary} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div>
            <label htmlFor="specialization">Specialization:</label>
            <select id="specialization" name="specialization" value={doctorDetails.specialization} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
               <option value="">Select specialization</option>
               {specialties.map(specialty => (
                 <option key={specialty} value={specialty.specialtyId}>{specialty.specialtyName}</option>
               ))}
             </select>
          </div>
          <div>
            <label htmlFor="qualifications">Qualifications:</label>
            <input type="text" id="qualifications" name="qualifications" value={doctorDetails.qualifications} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div>
            <label htmlFor="consultationFees">Consultation Fees:</label>
            <input type="text" id="consultationFees" name="consultationFees" value={doctorDetails.consultationFees} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div>
            <label htmlFor="city">City:</label>
            <select id="city" name="city" value={doctorDetails.city} onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3
            text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option value="">Select city</option>
            {cities.map(city => (
                <option key={city} value={city.cityId}>{city}</option>
            ))}
            </select>

          </div>
          <div>
            <label htmlFor="yearOfExperience">Years of Experience:</label>
            <input type="text" id="yearOfExperience" name="yearOfExperience" value={doctorDetails.yearOfExperience} onChange={handleChange}
             className="appearance-none border border-blue-300 rounded w-full py-2 px-3
             text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
        </div>
        <button type="submit"
        className="py-3 px-10 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
        rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10
        focus:ring-4 focus:ring-gray-200 font-Poppins mt-4"
        >Update Doctor Details</button>
      </form>
    </div>
  );
};

export default (UpdateDoctorDetails);
