import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp, otpSuccess, otpFailure } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Lottie from 'lottie-react';
import { api } from "../../utils/Api";
import otp_lottie from '../../lottiefiles/otp.json'
import OtpInput from 'react-otp-input'; // Import OtpInput

const OtpVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state);
  const [otp, setOtp] = useState(""); // State to store OTP input
  const [countdown, setCountdown] = useState(60);
  const [showResendOtp, setShowResendOtp] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const OtpSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required").min(6, "OTP must be exactly 6 digits").max(6, "OTP must be exactly 6 digits"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { otp } = values;
    console.log("otp", otp)
    setOtp(otp);
    dispatch(verifyOtp(otp));
    setIsLoading(true);
    try {
      const response = await api.put(`/auth/verify/${otp}`).catch((error) => {
        throw error;
      });

      if (response.data.status === "success") {
        // Extract the token and user type from the response
        const { token, type: userType } = response.data.data;

        dispatch(otpSuccess(token, userType, "OTP submitted successfully."));
        setSuccessMessage("OTP submitted successfully.");
        resetForm();
        // Navigate based on the user type
        if (userType === 2) {
          navigate("/doctorDetailForm");
        } else if (userType === 1) {
          navigate("/userDetailForm");
        } else {
          // Handle other user types or errors as appropriate
          console.error("Unexpected user type", userType);
          setErrorMessage("Unexpected user type. Please contact support.");
        }
      } else {
        dispatch(otpFailure("Invalid OTP"));
        setErrorMessage("Invalid OTP");
      }
    } catch ({ response: errorResponse }) {
      const {statusCode} = errorResponse.data
      if(statusCode === 400){
        const {errors} = errorResponse.data
        errors.forEach(error => {
          console.log("Error", error.msg)
          dispatch(otpFailure(error.msg));
          setErrorMessage(error.msg);
        });
      }else{
        const { message } = errorResponse.data;
        dispatch(otpFailure(message));
        setErrorMessage(message);
      }

    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    try {
      await api.post('/auth/otp-resend');
      setCountdown(60);
      setShowResendOtp(false);
      setSuccessMessage("OTP resent successfully.");
    } catch (error) {
      console.error('Failed to resend OTP', error);
      setErrorMessage("Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setShowResendOtp(true);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    let messageTimeout;

    if (successMessage) {
      messageTimeout = setTimeout(() => setSuccessMessage(""), 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => setErrorMessage(""), 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  return (
    <div
      className="w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3"
    >

      <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Verify your OTP </h2>
        <p className="text-gray-500 text-center font-Poppins">An OTP pin has been sent to your phone, enter the code to proceed</p>
        <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>
          <Lottie animationData={otp_lottie} width={300} height={300} loop={true} />
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={OtpSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="bg-white shadow rounded px-8 pt-6 pb-8 mb-4">
            {successMessage && (
              <div className="bg-green-200 p-2 text-green-700 mb-4 text-sm rounded">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-200 p-2 text-red-700 mb-4 text-sm rounded">
                {errorMessage}
              </div>
            )}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="otp"
              >
                OTP verification
              </label>
              <Field
                className="appearance-none border border-blue-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="otp"
                name="otp"
                type="text"
                placeholder="Enter OTP"
              />
              <ErrorMessage
                name="otp"
                component="div"
                className="text-red-500 text-xs italic"
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <button
                class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                focus:ring-gray-200 w-full mt-5 font-Poppins"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Verify OTP'}
              </button>
            </div>
            {!showResendOtp ? (
                <p className="text-gray-500 text-xs mb-2">Resend OTP in {countdown} seconds</p>
              ) : (
                <button
                  type="button"
                  onClick={handleResendOtp}
                  className="text-blue-500 text-xs mb-2"
                >
                  Resend OTP
                </button>
              )}
          </Form>
        )}
      </Formik>
      </div>
    </div>
  );
};

export default OtpVerification;
