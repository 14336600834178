import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import payment_success_lottie from '../lottiefiles/payment_success.json';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/userDashboard'); // Redirect to /userDashboard after 5 seconds
    }, 4000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div className='bg-gray-100 w-screen h-screen flex justify-center items-center'>
      <div className='flex flex-col justify-center items-center max-w-7xl'>
        <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Payment successful </h2>
        <p className="text-gray-500 text-center font-Poppins">Your payment is successful, thank you for using Kenecare 🥳 </p>
        <div className='w-1/2 flex justify-center items-center'>
          <Lottie animationData={payment_success_lottie} width={300} height={300} loop={true} />
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccessPage;
