import React, { useState , useEffect} from 'react';
import { api } from '../../utils/Api';
import profile_icon from '../../images/profile_icon.png'
import TextFolding from '../../utils/TextFolding';
import { Link } from 'react-router-dom';
const AppointmentForm = ({ doctorId, specialtyId, onClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    patientName: '',
    patientNumber: '',
    symptoms: '',
    appointmentType: '',
    appointmentDate: '',
    appointmentTime: '',
  });

  const selectedTime = [
    { timeId: 1, appointmentTime: '07:00' },
    { timeId: 2, appointmentTime: '07:30' },
    { timeId: 3, appointmentTime: '08:00' },
    { timeId: 4, appointmentTime: '08:30' },
    { timeId: 5, appointmentTime: '09:00' },
    { timeId: 6, appointmentTime: '09:30' },
    { timeId: 7, appointmentTime: '10:00' },
    { timeId: 8, appointmentTime: '10:30' },
    { timeId: 9, appointmentTime: '11:00' },
    { timeId: 10, appointmentTime: '11:30' },
    { timeId: 11, appointmentTime: '12:00' },
    { timeId: 12, appointmentTime: '12:30' },
    { timeId: 13, appointmentTime: '13:00' },
    { timeId: 14, appointmentTime: '13:30' },
    { timeId: 15, appointmentTime: '14:00' },
    { timeId: 16, appointmentTime: '14:30' },
    { timeId: 17, appointmentTime: '15:00' },
    { timeId: 18, appointmentTime: '15:30' },
    { timeId: 19, appointmentTime: '16:00' },
    { timeId: 20, appointmentTime: '16:30' },
    { timeId: 21, appointmentTime: '17:00' },
    { timeId: 22, appointmentTime: '17:30' },
    { timeId: 23, appointmentTime: '18:00' },
  ]

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    let messageTimeout;
    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  const handleSubmit = async (e, actions) => {

    e.preventDefault();
    setIsLoading(true);
      const values = { ...formData };
      values.doctorId = doctorId;
      values.specialtyId = specialtyId;
      try {
        const response = await api.post('/patients/appointments', values).catch((error) => {
          throw error;
        });
        const payment = response.data.data.paymentUrl;
        if (payment) {
          setSuccessMessage('Appointment booked successfully. Redirecting to payment...');
          setErrorMessage('');
          setTimeout(() => {
            window.location.href = payment;
            onClose();
          }, 3000);
          actions.resetForm();
          values('');
        } else {
          setSuccessMessage('Appointment booked successfully.');
          setErrorMessage('');
        }
      } catch (error) {
        let errorMessage = ""
        const errors = error.response.data.errors;
        if(errors){
          errors.forEach(error => {
            errorMessage += error.msg + "\n";
          });
        }
        setErrorMessage(errorMessage);
        setSuccessMessage('');
      }
      setIsLoading(false);
  };

  return (
    <div className="lg:pl-72 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white flex flex-col items-center justify-center p-3 w-popup-width sm-w-full rounded-md shadow-md">
      <div className="w-full">
          <button className="text-red-500 mb-3" onClick={onClose}>
              Close
            </button>
          </div>

        <h2 className="sm:text-lg text-gray-700 font-semibold text-center mb-4 text-sm font-Poppins">Doctor Appointment Form</h2>
            {successMessage && (
              <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                {errorMessage}
              </div>
            )}
        <form className='flex flex-col w-full gap-5' onSubmit={handleSubmit}>
          <input
            type="text"
            name="patientName"
            placeholder="Patient Name"
            value={formData.patientName}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <input
            type="text"
            name="patientNumber"
            placeholder="Patient Number"
            value={formData.patientNumber}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <input
            type="text"
            name="symptoms"
            placeholder="Symptoms"
            value={formData.symptoms}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <select
            name="appointmentType"
            value={formData.appointmentType}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Appointment Type</option>
            <option value="online_consultation">online_consultation</option>
            <option value="patient_visit">patient_visit</option>
            <option value="doctor_visit">doctor_visit</option>
            {/* Add more options as needed */}
          </select>

          <input
            type="date"
            name="appointmentDate"
            value={formData.appointmentDate}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />

          <select
            name="appointmentTime"
            value={formData.appointmentTime}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Appointment Time</option>
            {selectedTime.map(time => (
              <option key={time.timeId} value={time.appointmentTime}>
                {time.appointmentTime}
              </option>
            ))}
          </select>

            <input type="hidden" name="doctorId" value={doctorId} />
            <input type="hidden" name="specialtyId" value={specialtyId} />
          {/* Add more input fields for appointment time slot and any other fields */}
          <button type="submit"
          disabled={isLoading}
            class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
            rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
            focus:ring-gray-200 w-full mt-5 font-Poppins"
          >
            {isLoading ? 'Loading...' : 'Submit Appointment'}
          </button>
        </form>
      </div>
    </div>
  );
};

const DoctorPopup = ({ doctor, onClose }) => {
    const [showAppointmentForm, setShowAppointmentForm] = useState(false);

    const handleBookAppointment = () => {
      setShowAppointmentForm(true);
    };

    return (
      <>
        {showAppointmentForm ? (
          <AppointmentForm
          doctorId={doctor.doctorId}
          specialtyId={doctor.specialtyId}
            onClose={() => setShowAppointmentForm(false)}
          />
        ) : (
    <div className="fixed inset-0 lg:pl-72 flex items-center justify-center bg-gray-800 bg-opacity-50 overflow-y-auto">
      <div className="bg-white flex flex-col items-center justify-center p-3 w-popup-width sm-w-full rounded-md shadow-md sm:mt-0 sm:mb-0 mb-6 mt-popup-mt">
        <div className='w-full'>
            <button className="text-red-500 mb-3" onClick={onClose}>Close</button>
        </div>
        <img src={doctor.profilePic || profile_icon} alt={doctor.firstName} className="w-48 h-48 rounded-full mb-2" />
        <p className="text-center font-Poppins">{`${doctor.title} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`}</p>
        <p className='text-xs'><span className='text-gray-900 font-semibold'>Doctor Id:</span>  {doctor.doctorId}</p>
        <div className='w-full font-Poppins text-sm text-gray-700'>
            <div className='w-48 h-0.5 bg-orange-500 my-3'></div>
            <p><span className='text-gray-900 font-semibold'>Gender:</span>  {doctor.gender}</p>
            <p><span className='text-gray-900 font-semibold'>specialization:</span>  {doctor.specialization}</p>
            <p><span className='text-gray-900 font-semibold'>qualifications:</span>  {doctor.qualifications}</p>
            <p><span className='text-gray-900 font-semibold'>Years of experience:</span>  {doctor.yearOfExperience}</p>
            <p><span className='text-gray-900 font-semibold'>email:</span>  {doctor.email}</p>
            <p><span className='text-gray-900 font-semibold'>phone:</span>  {doctor.mobileNumber}</p>
            <p><span className='text-gray-900 font-semibold'>Location:</span>  {doctor.location}</p>
            <p><span className='text-gray-900 font-semibold'>ConsultationFee:</span>  {doctor.consultationFee}</p>
            <Link
            to={`/doctorDetails/${doctor.doctorId}`}
            className='mt-7 text-blue-500 hover:text-blue-400 font-Poppins font-semibold'
            >
              view more about doctor ...
            </Link>
            <div className='w-full mt-3 mb-5'>
              <span className='text-gray-900 font-semibold'>ProfessionalSummary:</span>
              <TextFolding initialText={doctor.professionalSummary} foldThreshold={200} />
            </div>
        </div>
        <button onClick={handleBookAppointment}
        className="py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins"
        >
              Book Appointment
            </button>
      </div>
    </div>
      )}
      </>
  );
};

export default DoctorPopup;
