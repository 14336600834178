import React from 'react'
import Lottie from 'lottie-react';
import UserNavBar from '../../components/UserNavBar';
import doctor_icon_lottie from'../../lottiefiles/doctor_icon_lottie.json'
import All_doctors from '../../components/userDashboard/AllDoctors';

const AllDoctors = () => {
  return (
    <div className='bg-gray-50 overflow-x-hidden pb-10 w-full h-screen'>
        <UserNavBar/>

        <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-blue-100 pt-16'>
        <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
            <div className='sm:w-1/2 flex justify-center items-center'>
            <Lottie className='w-full' animationData={doctor_icon_lottie} loop={true} />
            </div>
            <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
                <h1 className='text-xl text-center font-bold font-Poppins'>All Doctors</h1>
                <p className='text-center font-Poppins'>
                All the doctors are listed here. You can view their profile and book an appointment with them.
                </p>
            </div>
        </div>
        </div>

      <div className='flex justify-center items-center flex-row lg:pl-72 lg:px-0 px-4'>
        <div className='w-full items-center mt-10'>
          <All_doctors/>
          </div>
      </div>

  </div>
  )
}

export default AllDoctors
