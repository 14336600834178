import React from 'react';

const StarRating = ({ rating }) => {
  // Ensure rating is a number and within the range 0 to 5
  const validRating = isNaN(rating) ? 0 : Math.min(Math.max(rating, 0), 5);
  const fullStars = Math.floor(validRating);
  const halfStars = validRating % 1 !== 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div className="flex">
      {[...Array(fullStars)].map((_, index) => (
        <svg key={index} className="w-6 h-6 text-yellow-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z"/>
        </svg>
      ))}
      {[...Array(halfStars)].map((_, index) => (
        <svg key={index + fullStars} className="w-6 h-6 text-yellow-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2.75L13.847 8.386l5.656.448-4.393 3.703 1.374 5.716-4.484-2.71-4.485 2.71L9.89 12.537 5.497 8.834l5.656-.448L12 2.75z" fillRule="evenodd"/>
        </svg>
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <svg key={index + fullStars + halfStars} className="w-6 h-6 text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2.75L13.847 8.386l5.656.448-4.393 3.703 1.374 5.716-4.484-2.71-4.485 2.71L9.89 12.537 5.497 8.834l5.656-.448L12 2.75z" fillRule="evenodd"/>
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
