import React from 'react'
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar,
    ConversationHeader, TypingIndicator, MessageSeparator, Sidebar, ConversationList, Conversation, Search
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

const UserChats = ({ chatData }) => {

    return (
      <div className='lg:w-3/4 w-full sm:h-chart-h h-full max-w-7xl' style={{ position: "relative" }}>
        <MainContainer responsive>
          <Sidebar position="left" scrollable={false}>
            <Search placeholder="Search..." />
            <ConversationList>
              {chatData.map(conversation => (
                <Conversation key={conversation.id} name={conversation.name} lastSenderName={conversation.lastSenderName} info={conversation.info} unreadCnt={conversation.unreadCnt} unreadDot={conversation.unreadDot}>
                  {conversation.avatar && <Avatar src={conversation.avatar} name={conversation.name} status={conversation.status} />}
                </Conversation>
              ))}
            </ConversationList>
          </Sidebar>

          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar src={chatData[0].avatar} name={chatData[0].name} />
              <ConversationHeader.Content userName={chatData[0].name} info="Active 10 mins ago" />
            </ConversationHeader>
            <MessageList typingIndicator={<TypingIndicator content={`${chatData[0].name} is typing`} />}>
              {/* Assuming first conversation is active */}
              {chatData[0].messages.map((message, index) => (
                <React.Fragment key={index}>
                  {index === 0 || (index > 0 && message.date !== chatData[0].messages[index - 1].date) && (
                    <MessageSeparator content={message.date} />
                  )}
                  <Message model={message.model}>
                    {message.model.position === 'single' && <Avatar src={chatData[0].avatar} name={chatData[0].name} />}
                  </Message>
                </React.Fragment>
              ))}
            </MessageList>
            <MessageInput placeholder="Type message here" />
          </ChatContainer>
        </MainContainer>
      </div>
    );
  };

  export default UserChats;
