import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { api } from '../../utils/Api';
import {uploadDoctorCouncilDocument} from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import council_registration_lottie from '../../lottiefiles/council_regristration.json'

// Validation Schema
const validationSchema = Yup.object().shape({
  councilId: Yup.string().required('Council is required'),
  regNumber: Yup.string().required('Registration number is required'),
  regYear: Yup.number()
    .required('Registration year is required')
    .integer('Registration year must be an integer')
    .min(1900, 'Registration year is too early')
    .max(new Date().getFullYear(), 'Registration year cannot be in the future'),
  regCertificate: Yup.mixed()
    .required('A file is required')
    .test('fileType', 'Unsupported file format. Acceptable formats include PDF, PNG, and JPG.', value => value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type)),
    certIssuedDate: Yup.date().required('Certificate issued date is required'),
    certExpiryDate: Yup.date().required('Certificate expiry date is required'),
});

const DoctorCouncilRegistrationData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [councils, setCouncils] = useState([]);
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    api.get('/medical-councils')
    .then((response) => {
      if (response.data.status === 0) {
          // Handle error if needed
      } else {
          // Set the city state with the data from the API
          setCouncils(response.data.data);
      }
  })
  .catch((error) => {
      // Handle error if the API request fails
  });
  }, []);

  const handleUpload = async (values, { resetForm }) => {
    // Convert values to the correct types
    values.councilId = parseInt(values.councilId, 10);
    values.regYear = parseInt(values.regYear, 10);

    const formData = new FormData();
    formData.append("councilId", values.councilId);
    formData.append("regYear", values.regYear);
    // Append other fields as necessary

    const token = localStorage.getItem('token');
    const type = localStorage.getItem('type');
    setIsLoading(true);
    try {
      const response = await api.post("/doctors/council-registration", values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(uploadDoctorCouncilDocument(response.data, type, token));
      setSuccessMessage("Medical documents submitted successfully.");
      navigate('/uploadDoctorProfilePicture');
      resetForm();
    } catch (error) {
      setErrorMessage("Bad Request: Please check your input data.");
    }
    setIsLoading(false);
  };


  useEffect(() => {
    let messageTimeout;

    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage("");
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  return (
      <Formik
        initialValues={{
          councilId: '',
          regNumber: '',
          regYear: '',
          regCertificate: null,
          certIssuedDate: '',
          certExpiryDate: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(handleUpload)}
      >
        {({ setFieldValue }) => (
          <Form >
            <div
              className="w-screen sm:h-screen flex justify-center sm:flex-row flex-col items-center bg-gray-100 px-3"
            >

          <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>
              <div className='flex flex-col justify-center items-center sm:w-3/4'>
              <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Doctor council registration details </h2>
              <p className="text-gray-500 text-center font-Poppins mb-2">Enter you council registration details and submit</p>
              <Lottie animationData={council_registration_lottie} width={300} height={300} loop={true} />
              </div>

            <div className="flex flex-col gap-4 bg-white px-6 rounded-lg shadow py-14 w-full sm:w-2/3 max-w-5x">

              {successMessage && (
                <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                  {successMessage}
                </div>
              )}

              {errorMessage && (
                <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                  {errorMessage}
                </div>
              )}

              <div className='flex flex-col'>
              <label htmlFor="specialization">Select medical council</label>
              <Field as="select" name="councilId"
              className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option value="">Select Council</option>
                {councils.map((council) => (
                  <option key={council.councilId} value={council.councilId}>
                    {council.councilName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="councilId" component="div" className="text-red-500" />
              </div>


              <div className='flex flex-col'>
              <label htmlFor="specialization">Registration Number</label>
              <Field name="regNumber" type="text" placeholder="Registration Number"
              className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              <ErrorMessage name="regNumber" component="div" className="text-red-500" />
              </div>


              <div className='flex flex-col'>
                <label htmlFor="regYear">Registration Year</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number" name="regYear" id="regYear" min="1900" max={new Date().getFullYear()} />
                <ErrorMessage name="regYear" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="regCertificate">Registration Certificate</label>
                <input
                  name="regCertificate"
                  type="file"
                  onChange={(event) => setFieldValue('regCertificate', event.currentTarget.files[0])}
                  className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="regCertificate" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="certIssuedDate">Certificate Issued Date</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date" name="certIssuedDate" id="certIssuedDate" />
                <ErrorMessage name="certIssuedDate" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="certExpiryDate">Certificate Expiry Date</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date" name="certExpiryDate" id="certExpiryDate" />
                <ErrorMessage name="certExpiryDate" component="div" className="text-red-500" />
              </div>

              <button type="submit"
              disabled={isLoading}
              class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
              rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
              focus:ring-gray-200 w-full mt-2 font-Poppins"
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
              </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
  );
};

export default DoctorCouncilRegistrationData;
