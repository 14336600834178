import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchDoctorAppointmentsHistory } from '../../redux/actions';
import { Link } from "react-router-dom";
import { Tooltip } from "flowbite-react";
import UserPrescription from "./UserPrescription";
import UserProspondmessage from './UserPostponedMessage';

const DoctorAppointmentHistory = ({ doctorAppointments, fetchDoctorAppointmentsHistory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(5);
  const [showPrescription, setShowPrescription] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null); // Store the selected appointment ID
  const [showProspondMessage, setShowProspondMessage] = useState(false);

  useEffect(() => {
    fetchDoctorAppointmentsHistory(); // Dispatch an action to fetch doctor appointment data
  }, [fetchDoctorAppointmentsHistory]);

  const isTimePassed = (appointmentTime) => {
    const fifteenMinutesInMilliseconds = 15 * 60 * 1000; // 15 minutes in milliseconds
    const appointmentDateTime = new Date(appointmentTime).getTime();
    const currentTime = new Date().getTime();
    return appointmentDateTime + fifteenMinutesInMilliseconds < currentTime;
  };

  const statusColorMap = {
    approved: 'text-green-600',
    pending: 'text-blue-500',
    postponed: 'text-orange-500 cursor-pointer hover:underline',
    cancelled: 'text-red-500'
  };

  // Pagination logic
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = doctorAppointments.data.slice(indexOfFirstAppointment, indexOfLastAppointment);
  const totalAppointments = doctorAppointments.data.length;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalAppointments / appointmentsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Handle viewing prescription
  const handleViewPrescription = (appointmentId) => {
    setSelectedAppointmentId(appointmentId); // Set the selected appointment ID
    setShowPrescription(true); // Show the prescription modal
  };

  return (
    <div className="overflow-x-auto lg:px-0 px-4">
      {/* Pass the selected appointment ID to the UserPrescription component */}
      {showPrescription && selectedAppointmentId && (
        <UserPrescription setShowPrescription={setShowPrescription} appointmentId={selectedAppointmentId} />
      )}
      {showProspondMessage && <UserProspondmessage setShowProspondMessage={setShowProspondMessage} />}

      {doctorAppointments.loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
            h-32 w-32 flex justify-center items-center">
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
        </div>
      ) : doctorAppointments.error ? (
        <p>Error: {doctorAppointments.error}</p>
      ) : (
        <div>
          <table className="table-auto">
            <thead>
              <tr className="text-white text-xs font-Poppins bg-blue-700">
                <th className="border px-4 py-2">Appointment ID</th>
                <th className="border px-4 py-2">Patient Name</th>
                <th className="border px-4 py-2">Doctor</th>
                <th className="border px-4 py-2">Appointment Type</th>
                <th className="border px-4 py-2">Mobile Number</th>
                <th className="border px-4 py-2">Symptoms</th>
                <th className="border px-4 py-2">Appointment Status</th>
                <th className="border px-4 py-2">Created At</th>
                <th className="border px-4 py-2">Appointment Date</th>
                <th className="border px-4 py-2">Join Meeting</th>
              </tr>
            </thead>
            <tbody>
              {currentAppointments.map((appointment, index) => (
                <tr key={appointment.appointmentId} className={index % 2 === 0 ? 'bg-blue-50 text-xs' : 'bg-white text-xs'}>
                  <td className="border px-4 py-2">{appointment.appointmentId}</td>
                  <td className="border px-4 py-2 hover:cursor-pointer">
                    <span
                      onClick={() => handleViewPrescription(appointment.appointmentId)} // Call the handler on click
                      className="text-gray-700 font-semibold hover:underline"
                    >
                      <Tooltip content="View prescription" placement="top" trigger="hover" animation="duration-150">
                        {appointment.patientNameOnPrescription}
                      </Tooltip>
                    </span>
                  </td>
                  <td className="border px-4 py-2">
                    <Link to={`/doctorDetails/${appointment.doctorId}`} target="_blank" className="text-gray-700 font-semibold hover:underline">
                      <Tooltip content="View doctor details" placement="top" trigger="hover" animation="duration-150">
                        {appointment.doctorName}
                      </Tooltip>
                    </Link>
                  </td>
                  <td className="border px-4 py-2">{appointment.appointmentType}</td>
                  <td className="border px-4 py-2">{appointment.patientMobileNumber}</td>
                  <td className="border px-4 py-2">{appointment.patientSymptoms}</td>
                  <td className="border px-4 py-2">
                    {appointment.appointmentStatus.toLowerCase() === 'postponed' ? (
                      <span onClick={() => setShowProspondMessage(true)} className={statusColorMap[appointment.appointmentStatus.toLowerCase()]}>
                        <Tooltip content="View postponed message" placement="top" trigger="hover" animation="duration-150">
                          {appointment.appointmentStatus.charAt(0).toUpperCase() + appointment.appointmentStatus.slice(1)}
                        </Tooltip>
                      </span>
                    ) : (
                      <span className={statusColorMap[appointment.appointmentStatus.toLowerCase()] || 'text-gray-500'}>
                        {appointment.appointmentStatus.charAt(0).toUpperCase() + appointment.appointmentStatus.slice(1)}
                      </span>
                    )}
                  </td>
                  <td className="border px-4 py-2">{appointment.createdAt}</td>
                  <td className="border px-4 py-2">{appointment.appointmentTime} | {appointment.appointmentDate}</td>

                  <td className="border px-4 py-2 ">
                    {appointment.appointmentStatus === 'approved' && !isTimePassed(appointment.appointmentTime) && (
                      <a href={appointment.meetingJoinUrl} target="_blank" className='cursor-pointer text-green-600 hover:text-green-500'
                        rel="noopener noreferrer">
                        <Tooltip content="Join meeting" placement="top" trigger="hover" animation="duration-150">
                          <svg className="w-6 h-6 text-green-500" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z" clipRule="evenodd"/>
                          </svg>
                        </Tooltip>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center mt-4">
            <button
              className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg
            hover:bg-gray-100 hover:text-gray-700 text-xs"
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`px-3 py-1 ${currentPage === number ?
                  'px-3 h-8 leading-tight text-gray-700 text-xs bg-blue-300 hover:bg-gray-100 hover:text-gray-700'
                  : 'bg-white hover:bg-gray-100 text-gray-500 '}`}
              >
                {number}
              </button>
            ))}
            <button
              className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg
            hover:bg-gray-100 hover:text-gray-700 text-xs"
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={currentPage === pageNumbers.length}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  doctorAppointments: state.doctorAppointmentHistory, // Replace with your actual Redux state
});

const mapDispatchToProps = (dispatch) => ({
  fetchDoctorAppointmentsHistory: () => dispatch(fetchDoctorAppointmentsHistory()), // Replace with your actual action creator
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAppointmentHistory);
