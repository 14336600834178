import React, { useEffect, useState } from 'react';
import { api } from '../../utils/Api';
import profile_icon from '../../images/profile_icon.png';
import { Link } from 'react-router-dom';

const AllDoctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State to keep track of the search term
  const [isLoading, setIsLoading] = useState(true); // State to keep track of loading

  useEffect(() => {
    setIsLoading(true); // Start loading
    api.get('/doctors')
      .then(response => {
        setDoctors(response.data.data); // Assuming the data structure from the example you provided
        setIsLoading(false); // End loading
      })
      .catch(error => {
        console.error('Error fetching doctors:', error);
        setIsLoading(false); // End loading on error
      });
  }, []);

  // Filter doctors based on search term
  const filteredDoctors = doctors.filter(doctor =>
    `${doctor.firstName} ${doctor.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
      {/* Search input field */}
      <div className='w-full py-4 flex justify-center items-center'>
        <div className="mb-4 sm:w-1/2">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term as user types
            className="p-2 border border-gray-300 rounded-lg w-full"
          />
        </div>
      </div>

      {/* Display loading state or filtered doctors */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
          h-32 w-32 flex justify-center items-center"
          >
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>

        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {filteredDoctors.map((doctor) => (
            <Link to={`/doctorDetails/${doctor.doctorId}`} key={doctor.doctorId}>
              <div className="bg-white hover:border-blue-500 hover:border shadow-md rounded-lg p-4 flex flex-col items-center">
                <img
                  src={doctor.profilePic || profile_icon}
                  alt={`${doctor.firstName} ${doctor.lastName}`}
                  className="w-32 h-32 object-cover rounded-full mb-4"
                />
                <h2 className="text-sm text-gray-600 font-bold">{`${doctor.title} ${doctor.middleName} ${doctor.firstName} ${doctor.lastName}`}</h2>
                <p className="text-gray-600 text-sm">{doctor.specialization}</p>
                <p className="text-blue-600 font-semibold text-sm">
                  Fee: SLE{doctor.consultationFee}
                </p>
                <p className="text-gray-500 text-sm">{doctor.location}</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default AllDoctors;
