import axios from 'axios';
const baseURL = 'https://api.kenecare.com/api/v1';

const api = axios.create({
  baseURL,
  timeout: 500000,
  headers: {
    'Content-Type': 'application/json', // Set content type to JSON
    'Accept': 'application/json', // Set accept type to JSON
  },
});

// Function to set JWT token as authorization header
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  }
};

api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("token") || null;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }
  return config;
}, (error) => {
  return Promise.reject(error)
})

api.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    //todo handle unauthorized error
    // console.log("Unauthorized Error")
  } else if (status === 404) {
    //todo handle not found error
    // console.log("Not Found Error")
  } else if (status === 400) {
  }else {
    //todo handle other errors
    // console.log("Other error")
  }
  return Promise.reject(error)
})

export { api, setAuthToken };

