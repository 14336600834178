// UserProfile.js
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../redux/actions";
import profile_icon from '../../images/profile_icon.png'
import { api } from "../../utils/Api";

const UserProfile = ({ userProfile, fetchUserProfile }) => {
  useEffect(() => {
    fetchUserProfile(); // Dispatch an action to fetch user profile data
  }, [fetchUserProfile]);

  const profileImageUrl = userProfile.data.profilePic
  ? `${userProfile.data.profilePic}`
  : 'default_image_url';

  return (
    <div className="w-full bg-white py-2">
      {/* <h1 className="text-2xl font-bold mb-4">User Profile</h1> */}
      {userProfile.loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
          h-32 w-32 flex justify-center items-center"
          >
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
        </div>
      ) : userProfile.error ? (
        <p>Error: {userProfile.error}</p>
      ) : (
        <div className="bg-white p-4 shadow rounded-lg">
          <img
            src={profileImageUrl || profile_icon} // Replace with your API URL and image path
            alt="Profile Pic"
            className="w-20 h-20 rounded-full mx-auto bg-gray-200"
          />
          <p className="text-sm  text-center mt-4 text-gray-800">
            {`${userProfile.data.firstName} ${userProfile.data.middleName} ${userProfile.data.lastName}`}
          </p>
          <p className="text-center text-sm text-gray-800">Patient ID: {userProfile.data.patientId}</p>
          <p className="text-center text-sm text-gray-500">{userProfile.data.mobileNumber}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.userProfile, // Replace with your actual Redux state
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()), // Replace with your actual action creator
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
