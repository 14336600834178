import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../utils/Api';

const UploadProfilePictureSchema = Yup.object().shape({
  profilePicture: Yup.mixed()
    .required("A profile picture is required")
    .test("fileSize", "The file is too large", value => value && value.size <= 1024 * 1024) // Validate file size (1MB)
    .test("fileType", "Unsupported file format", value => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)), // Validate file type
});

const UpdateProfilePix = () => {
    const [imagePreview, setImagePreview] = useState(); // Initialize with an empty string
    const [uploadError, setUploadError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentProfilePix, setCurrentProfilePix] = useState('');

    useEffect(() => {
        const fetchProfilePicture = async () => {
            try {
                const response = await api.get('/doctors/profile');
                setCurrentProfilePix(response.data.data.profilePic);
                setImagePreview(response.data.data.profilePic); // Set the current profile picture as default
            } catch (error) {
            }
        };
        fetchProfilePicture();
    }, []);

    const handleFileChange = (event, setFieldValue, setFieldTouched) => {
      const file = event.currentTarget.files[0];
      if (file) {
        setFieldValue("profilePicture", file);
        setImagePreview(URL.createObjectURL(file));
      }
      setFieldTouched("profilePicture", true, false);
    };

    return (
      <Formik
        initialValues={{ profilePicture: null }}
        validationSchema={UploadProfilePictureSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formData = new FormData();
          setIsLoading(true);
          formData.append('profilepic', values.profilePicture);
          api.patch('/doctors/profile', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              setSubmitting(false);
              window.location.reload();
            }).catch(error => {
              const errorMessage = error.response?.data?.message || "An error occurred during upload.";
              setUploadError(errorMessage); // Set the upload error message
              // Clear the error message after 3 seconds
              setTimeout(() => {
                setUploadError('');
              }, 3000);
              setSubmitting(false);
            });
            setIsLoading(false);
        }}
      >
        {({ setFieldValue, setFieldTouched, errors, touched }) => (
          <div className="w-full flex sm:flex-row-reverse flex-col justify-end py-5 rounded-lg shadow-lg bg-gray-50 px-4 max-w-7xl"
          data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
          >
            <div>
                <h2 className='mb-5 mt-5 text-lg text-blue-500 font-Poppins font-bold'>Update a profile image</h2>
                <p className="text-gray-500 text-center font-Poppins mb-2 text-xs">Select a profile by clicking on the profile icon and then upload</p>
            </div>

            <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>
              <Form className="flex flex-col items-center justify-center mx-auto w-64 text-center">
                <div className="flex flex-col items-center w-72">
                  <img className="w-48 h-48 rounded-full absolute" src={imagePreview || currentProfilePix} alt="Profile" />
                  <label className="w-48 h-48 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                    <img className="w-12" src="https://www.svgrepo.com/show/33565/upload.svg" alt="Upload" />
                    <input type="file" name="profilePicture" accept="image/*" onChange={(event) => handleFileChange(event, setFieldValue, setFieldTouched)} className="hidden" />
                  </label>
                </div>
                <button type="submit"
                class="py-3 px-3 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                focus:ring-gray-200 w-full mt-52 font-Poppins"
                disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Change Profile'}
                </button>
                {touched.profilePicture && errors.profilePicture && <div className="text-red-500 mt-2">{errors.profilePicture}</div>}
                {uploadError && <div className="text-red-500 mt-2">{uploadError}</div>}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    );
  };

export default UpdateProfilePix;
