import React, { useState, useEffect } from 'react';

const healthArticles = [
  {
    image: "https://via.placeholder.com/150",
    heading: "The Benefits of Regular Exercise",
    description: "Discover how regular physical activity can improve your health and well-being. From boosting your mood to strengthening your heart, find out the numerous benefits of staying active."
  },
  {
    image: "https://via.placeholder.com/150",
    heading: "Healthy Eating Tips",
    description: "Learn about healthy eating habits that can help you maintain a balanced diet. Get tips on meal planning, portion control, and choosing nutrient-rich foods."
  },
  {
    image: "https://via.placeholder.com/150",
    heading: "Managing Stress Effectively",
    description: "Explore strategies for managing stress in your daily life. Understand the impact of stress on your health and discover techniques to reduce and cope with stress."
  }
];

function Healthfeed() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a data fetch
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust this timeout as needed
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white">
      <h2 className="text-xl text-gray-600 font-bold mb-6 text-center">Health Feed</h2>
      {isLoading ? (
        <div className="text-center">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {healthArticles.map((article, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img src={article.image} alt={article.heading} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{article.heading.length > 15
                    ? `${article.heading.slice(0, 15)}...`
                    : article.heading}
                </h3>
                <p className="text-gray-700">
                  {article.description.length > 40
                    ? `${article.description.slice(0, 40)}...`
                    : article.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Healthfeed;
