import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { submitDoctorDetail } from '../../redux/actions';
import Lottie from 'lottie-react';
import { api } from '../../utils/Api';
import { useNavigate } from 'react-router-dom';
import user_detail_form_lottie from '../../lottiefiles/user_detail_form.json'


 // Define Yup validation schema
 const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstname: Yup.string().required('First Name is required'),
  middlename: Yup.string(),
  lastname: Yup.string().required('Last Name is required'),
  gender: Yup.string().required('Gender is required'),
  profileSummary: Yup.string().required('Profile Summary is required'),
  specialization: Yup.string().required('Specialization is required'),
  qualifications: Yup.string().required('Qualifications are required'),
  yearOfExperience: Yup.number().required('Year of Experience is required'),
  consultationfee: Yup.number().required('Consultation Fee is required'),
  city: Yup.string().required('City is required'),
});


const DoctorDetailForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [specialties, setSpecialties] = useState([]);
  const[city, setCity] = useState([]);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    api.get('/specialties')
    .then((response) => {
      if (response.data.status === 0) {
          // Handle error if needed
      } else {
          // Set the city state with the data from the API
          setSpecialties(response.data.data);
      }
  })
  .catch((error) => {
      // Handle error if the API request fails
  });
  }, []);

  useEffect(() => {
    api.get('/cities')
    .then((response) => {
      if (response.data.status === 0) {
          // Handle error if needed
      } else {
          // Set the city state with the data from the API
          setCity(response.data.data);
      }
  })
  .catch((error) => {
      // Handle error if the API request fails
  });
  }, []);


  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    // Convert specialtyId and cityId to integers
    const modifiedValues = {
      ...values,
      specialization: parseInt(values.specialization, 10), // or use +values.specializationId
      city: parseInt(values.city, 10), // or use +values.cityId
    };
    setIsLoading(true);
    try {
      await dispatch(submitDoctorDetail(modifiedValues)); // Use modifiedValues with converted types
      setSuccessMessage('Medical details submitted successfully.');
      setErrorMessage('');
      resetForm();
      navigate('/doctorCouncilRegistrationData');
    } catch (error) {
      setErrorMessage(error.toString());
      setSuccessMessage('');
    }
    setIsLoading(false);
  };


  useEffect(() => {
    let messageTimeout;

    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  return (
    <Formik
      initialValues={{
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        gender: '',
        profileSummary: '',
        specialization: '',
        qualifications: '',
        yearOfExperience: '',
        consultationfee: '',
        city: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
      <div
      className="w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3"
    >
        <div className='flex flex-col lg:flex-row justify-center items-center max-w-7xl'>
            <div className='flex flex-col justify-center items-center'>
              <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Doctor detail form </h2>
              <p className="text-gray-500 text-center font-Poppins mb-2">Enter you details below and submit</p>
              <Lottie animationData={user_detail_form_lottie} width={300} height={300} loop={true} className='w-1/2' />
            </div>


          <div className="sm:grid sm:grid-cols-2 flex flex-col gap-2 bg-white px-6 rounded-lg shadow py-14 w-full lg:w-2/3 lg:max-w-5x">

              {successMessage && (
                <div className="bg-green-200 p-2 text-green-700 mb-4 rounded col-span-2">
                  {successMessage}
                </div>
              )}

              {errorMessage && (
                <div className="bg-red-200 p-2 text-red-700 mb-4 rounded col-span-2">
                  {errorMessage}
                </div>
              )}


            <div className='flex flex-col'>
              <label htmlFor="title">Title</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                as="select" name="title" id="title">
                  <option value="">Select Title</option>
                  <option value="Dr.">Dr.</option>
              </Field>

              <ErrorMessage name="title" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="firstname">First Name</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text" name="firstname" id="firstname" />
              <ErrorMessage name="firstname" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="middlename">Middle Name</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text" name="middlename" id="middlename" />
              <ErrorMessage name="middlename" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="lastname">Last Name</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text" name="lastname" id="lastname" />
              <ErrorMessage name="lastname" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="gender">Gender</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              as="select" name="gender" id="gender">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
              </Field>
              <ErrorMessage name="gender" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
              <label htmlFor="yearOfExperience">Year of Experience</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number" name="yearOfExperience" id="yearOfExperience" />
              <ErrorMessage name="yearOfExperience" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
              <label htmlFor="consultationfee">Consultation Fee</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number" name="consultationfee" id="consultationfee" placeholder="SLE. "  />
              <ErrorMessage name="consultationfee" component="div" className="text-red-500" />
            </div>


            <div className='flex flex-col'>
              <label htmlFor="specialization">Specialization</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              as="select" name="specialization" id="specialization">
                <option value="">Select Specialization</option>
                {specialties.map((specialties) => (
                  <option key={specialties.specialtyId} value={specialties.specialtyId}>
                    {specialties.specialtyName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="specialization" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="city">City</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              as="select" name="city" id="city">
                <option value="">Select City</option>
                {city.map((city) => (
                  <option key={city.cityId} value={city.cityId}>
                    {city.cityName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="city" component="div" className="text-red-500" />
            </div>

            <div className='flex flex-col'>
              <label htmlFor="qualifications">Qualifications</label>
              <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text" name="qualifications" id="qualifications" placeholder="eg. Qualification 1 , Qualification 1 "  />
              <ErrorMessage name="qualifications" component="div" className="text-red-500" />
            </div>

              <div className='flex flex-col col-span-2'>
              <label htmlFor="profileSummary">Profile Summary</label>
              <Field className='bg-gray-50 border border-blue-400 rounded px-2'  as="textarea" name="profileSummary"
              id="profileSummary"  maxLength={900} />
              <ErrorMessage name="profileSummary" component="div" className="text-red-500" />
              </div>

          {/* Submit button */}
              <button type="submit"
              disabled={isLoading}
                class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                focus:ring-gray-200 w-full mt-2 font-Poppins col-span-2"
              >
              {isLoading ? 'Loading...' : 'Submit'}
              </button>
          </div>
        </div>
      </div>
      </Form>
    </Formik>
  );
};

export default DoctorDetailForm;
