import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchUserMedicalDetails } from '../../../redux/actions';
import { api } from "../../../utils/Api";

const UpdateMedicalDetails = ({ fetchMedicalDetail, fetchUserMedicalDetails }) => {
    const [medicalDetails, setMedicalDetails] = useState({
      height: '',
      weight: '',
      allergies: '',
      isDisabled: false,
      disabilityDesc: '',
      tobaccoIntake: false,
      tobaccoIntakeFreq: '',
      alcoholIntake: false,
      alcoholIntakeFreq: '',
      caffeineIntake: false,
      caffeineIntakeFreq: ''
    });

    useEffect(() => {
      fetchUserMedicalDetails();
    }, [fetchUserMedicalDetails]);

    useEffect(() => {
      if (!fetchMedicalDetail.loading && !fetchMedicalDetail.error && fetchMedicalDetail.data) {
        setMedicalDetails(fetchMedicalDetail.data);
      }
    }, [fetchMedicalDetail]);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setMedicalDetails(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      // try {
      //   // Assuming /patients/profile is the endpoint and it accepts PUT request
      //   const response = api.put('/patients/profile', medicalDetails);
      //   console.log('Success:', response.data);
      //   alert('Medical details updated successfully!');
      //   // Optionally, add additional state updates or navigation on success
      // } catch (error) {
      //   console.error('Error updating medical details:', error);
      //   alert('Failed to update medical details.');
      //   // Optionally, handle errors with more detailed UI feedback
      // }
    };

    if (fetchMedicalDetail.loading) {
      return <div className="w-full flex justify-center items-center" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
         <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
          h-32 w-32 flex justify-center items-center"
          >
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
        </div>;
    }

    if (fetchMedicalDetail.error) {
      return <p>Error: {fetchMedicalDetail.error}</p>;
    }

    return (
      <div className="p-4 w-full bg-gray-50 rounded-lg shadow-lg max-w-7xl"
      data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
      >
        <p className='mb-5 mt-5 text-lg text-center text-blue-500 font-Poppins font-bold'>Update Medical Details</p>
        <form onSubmit={handleSubmit}>
          <div className="grid sm:grid-cols-3 gap-2 w-full text-gray-500 text-sm font-Poppins">
            <div>
              <label htmlFor="height">Height:</label>
              <input type="text" id="height" name="height" value={medicalDetails.height} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div>
              <label htmlFor="weight">Weight:</label>
              <input type="text" id="weight" name="weight" value={medicalDetails.weight} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div>
              <label htmlFor="allergies">Allergies:</label>
              <input type="text" id="allergies" name="allergies" value={medicalDetails.allergies} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>

            <div class="flex items-center gap-4">
              <label htmlFor="isDisabled">Disabled:</label>
              <input type="checkbox" id="isDisabled" name="isDisabled" checked={medicalDetails.isDisabled} onChange={handleChange} />
            </div>

            <div>
              <label htmlFor="disabilityDesc">Disability Description:</label>
              <input type="text" id="disabilityDesc" name="disabilityDesc" value={medicalDetails.disabilityDesc} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div class="flex items-center gap-4">
              <label htmlFor="tobaccoIntake">Tobacco:</label>
              <input type="checkbox" id="tobaccoIntake" name="tobaccoIntake" checked={medicalDetails.tobaccoIntake} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="tobaccoIntakeFreq">Tobacco Intake Frequency:</label>
              <input type="text" id="tobaccoIntakeFreq" name="tobaccoIntakeFreq" value={medicalDetails.tobaccoIntakeFreq} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div class="flex items-center gap-4">
              <label htmlFor="alcoholIntake">Alcohol:</label>
              <input type="checkbox" id="alcoholIntake" name="alcoholIntake" checked={medicalDetails.alcoholIntake} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="alcoholIntakeFreq">Alcohol Intake Frequency:</label>
              <input type="text" id="alcoholIntakeFreq" name="alcoholIntakeFreq" value={medicalDetails.alcoholIntakeFreq} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div class="flex items-center gap-4">
              <label htmlFor="caffeineIntake">Caffeine:</label>
              <input type="checkbox" id="caffeineIntake" name="caffeineIntake" checked={medicalDetails.caffineIntake} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="caffeineIntakeFreq">Caffeine Intake Frequency:</label>
              <input type="text" id="caffeineIntakeFreq" name="caffeineIntakeFreq" value={medicalDetails.caffineIntakeFreq} onChange={handleChange}
               className="appearance-none border border-blue-300 rounded w-full py-2 px-3
               text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
          </div>
          <button type="submit"
          class="py-3 px-10 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
          rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10
          focus:ring-4 focus:ring-gray-200 font-Poppins mt-4"
          >Update Medical Details</button>
        </form>
      </div>
    );
  };

  const mapStateToProps = (state) => ({
    fetchMedicalDetail: state.fetchMedicalDetail
  });

  const mapDispatchToProps = (dispatch) => ({
    fetchUserMedicalDetails: () => dispatch(fetchUserMedicalDetails()), // Replace with your actual action creator
  });

  export default connect(mapStateToProps, mapDispatchToProps)(UpdateMedicalDetails);
