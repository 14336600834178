import React, { useEffect } from "react";
import { connect } from "react-redux";
import {fetchUserMedicalDetails} from '../../redux/actions'

const UserMedicalDetails = ({ fetchMedicalDetail, fetchUserMedicalDetails }) => {
  useEffect(() => {
    fetchUserMedicalDetails();
  }, [fetchUserMedicalDetails]);

  if (fetchMedicalDetail.loading) {
    return <p>Loading...</p>;
  }

  if (fetchMedicalDetail.error) {
    return <p>Error: {fetchMedicalDetail.error}</p>;
  }

  const { data } = fetchMedicalDetail;
  if (!data) {
    return <p>No medical history data available.</p>;
  }

  return (
    <div>
      {fetchMedicalDetail.loading ? (
        <p>Loading...</p>
      ) : fetchMedicalDetail.error ? (
        <p>Error: {fetchMedicalDetail.error}</p>
      ) : (
        <div className="p-4 w-full">
          <p className="text-lg text-gray-700 font-bold border-b mb-3 font-Poppins">Medical History</p>
          <div className="grid sm:grid-cols-3 gap-2 w-full text-gray-500 text-sm font-Poppins">
            <p><span className="font-bold">Height: </span>{fetchMedicalDetail.data.height}</p>
            <p><span className="font-bold">Weight: </span>{fetchMedicalDetail.data.weight}</p>
            <p><span className="font-bold">Allergies: </span>{fetchMedicalDetail.data.allergies}</p>
            <p><span className="font-bold">Disabled: </span>{fetchMedicalDetail.data.isDisabled ? 'Yes' : 'No'}</p>
            <p><span className="font-bold">Disability Description: </span>{fetchMedicalDetail.data.disabilityDesc}</p>
            <p><span className="font-bold">Tobacco: </span>{fetchMedicalDetail.data.tobaccoIntake ? 'Yes' : 'No'}</p>
            <p><span className="font-bold">Tobacco Intake Frequency: </span>{fetchMedicalDetail.data.tobaccoIntakeFreq}</p>
            <p><span className="font-bold">Alcohol: </span>{fetchMedicalDetail.data.alcoholIntake ? 'Yes' : 'No'}</p>
            <p><span className="font-bold">Alcohol Intake Frequency: </span>{fetchMedicalDetail.data.alcoholIntakeFreq}</p>
            <p><span className="font-bold">Caffeine: </span>{fetchMedicalDetail.data.caffineIntake ? 'Yes' : 'No'}</p>
            <p><span className="font-bold">Caffeine Intake Frequency: </span>{fetchMedicalDetail.data.caffineIntakeFreq}</p>
          </div>
        </div>
      )}
    </div>
  );
}


const mapStateToProps = (state) => ({
  fetchMedicalDetail: state.fetchMedicalDetail
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserMedicalDetails: () => dispatch(fetchUserMedicalDetails()), // Replace with your actual action creator
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMedicalDetails)

