import React, {useState} from 'react'
import UserNavBar from '../../components/UserNavBar'
import Lottie from 'lottie-react';
import set_lottie from '../../lottiefiles/settings_lottie.json'
import UpdateProfilePix from '../../components/userDashboard/settingsFolder/UpdateProfilePix';
import UpdateMedicalDetails from '../../components/userDashboard/settingsFolder/UpdateMedicalDetails';
import KenecareFamily from '../../components/userDashboard/settingsFolder/KenecareFamily';
import ChangePhoneNumber from '../../components/userDashboard/settingsFolder/ChangePhoneNumber';
import ForgottenPassword from '../../components/auth/ForgottenPassword';
import UserInfo from '../../components/userDashboard/settingsFolder/UserInfo';


import 'aos/dist/aos.css';
import aos from 'aos';
import { Link } from 'react-router-dom';

// Initialize AOS
aos.init();
const Settings = () => {

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const toggleForgotPasswordPopup = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return (
    <div className='bg-white overflow-x-hidden'>
     <UserNavBar />

     <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-blue-100 pt-20'>
        <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
          <div className='sm:w-1/2 flex justify-center items-center'>
            <Lottie className='w-full' animationData={set_lottie} loop={true} />
          </div>
          <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='text-xl text-center font-bold font-Poppins'>Settings</h1>
              <p className='text-center font-Poppins'>
                Upload your password, change profile picture add family members, and other settings here
              </p>
            </div>
        </div>
      </div>

     <div className='lg:pl-72 mt-14 mb-10 lg:px-10 px-3 flex justify-center'>
        <UpdateProfilePix/>
      </div>

      <div className='lg:pl-72 mt-8 mb-10 lg:px-10 px-3 flex justify-center'>
        <div className="relative bg-gray-50 flex flex-col justify-center py-5 sm:px-6 px-3 rounded-lg shadow-lg lg:px-8 w-full max-w-7xl"
          data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <p className="text-gray-500 text-center font-Poppins mb-2 text-xs">
            Change your password, input your current password followed by the new one
          </p>
        </div>

        <Link
          onClick={toggleForgotPasswordPopup}
          className="text-lg text-center text-blue-500 font-Poppins font-bold mt-5 cursor-pointer"
        >
          Reset your password here
        </Link>

        {/* {showForgotPassword && <ForgottenPassword setShowForgotPassword={setShowForgotPassword} />} */}
        </div>
      </div>

      <div className='lg:pl-72 mt-8 mb-10 lg:px-10 px-3 flex justify-center'>
        <UserInfo/>
      </div>

      <div className='lg:pl-72 mt-8 mb-10 lg:px-10 px-3 flex justify-center'>
        <UpdateMedicalDetails/>
      </div>

      <div className='lg:pl-72 mt- mb-10 lg:px-10 px-3 flex justify-center'>
        <ChangePhoneNumber/>
      </div>

      <div className='lg:pl-72 mt-8 mb-10 lg:px-10 px-3 flex justify-center' >
        <KenecareFamily/>
      </div>

      {showForgotPassword && <ForgottenPassword setShowForgotPassword={setShowForgotPassword} />}

    </div>
  )
}

export default Settings;
