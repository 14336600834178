import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { uploadDocument } from "../../redux/actions";
import { api } from "../../utils/Api";

// Validation Schema for Yup
const validationSchema = Yup.object().shape({
  documentName: Yup.string().required("Document name is required"),
  documentFile: Yup.mixed()
    .required("A file is required")
    .test(
      "fileType",
      "Unsupported File Format. Acceptable formats include PDF, PNG, and JPG.",
      value => value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type)
    ),
    password: Yup.string().required("Password is required"),
});

const DocumentUploadComponent = () => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (values, { resetForm }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("documentTitle", values.documentName); // Use the correct key for the document name if needed
    formData.append("medicalDocument", values.documentFile); // Make sure "documentFile" matches the key expected by the server
    formData.append("password", values.password);

    try {
      // Ensure api instance does not automatically set Content-Type to application/json for this request
      const response = await api.post("/patients/medical-records", formData, {
        headers: {
          // Let browser set the Content-Type for FormData correctly
          'Content-Type': 'multipart/form-data',
        },
      });

      // Assuming dispatch and success handling remains the same
      dispatch(uploadDocument(response.data));

      setSuccessMessage("Medical documents submitted successfully.");
      resetForm(); // Clear the form
    } catch (error) {
      console.error("Error uploading document", error);
      setErrorMessage("Bad Request: Please check your input data.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let messageTimeout;

    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage("");
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="p-4 w-full flex justify-center items-center">
      <Formik
        initialValues={{ documentName: "", documentFile: null, password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleUpload}
      >
        {({ setFieldValue }) => (
          <Form className="flex flex-col p-3">
            <p className="text-gray-600">upload medical document</p>

            {successMessage && (
              <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                {errorMessage}
              </div>
            )}

            <div className="flex sm:flex-row flex-col gap-4 bg-white py-3">
              <div className="flex flex-col w-full">
                <Field
                  name="documentName"
                  type="text"
                  placeholder="Document Name"
                  className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700
                    leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage
                  className="text-red-500 text-sm"
                  name="documentName"
                  component="div"
                />
              </div>

              <div className="w-full">
                <div className="relative w-full">
                  <Field
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter your password"
                  />

                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm"
                  >
                    {showPassword ? (
                      <svg class="w-6 h-6 text-blue-300"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z"/>
                        <path d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z"/>
                        <path d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z"/>
                      </svg>
                    ) : (
                      <svg class="w-6 h-6 text-blue-300"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/>
                      </svg>
                    )}
                  </button>
                </div>
                  <ErrorMessage
                    className="text-red-500 text-sm"
                    name="password"
                    component="div"
                  />
              </div>

              <div className="flex flex-col w-full">
                <input
                  name="documentFile"
                  type="file"
                  onChange={(event) =>
                    setFieldValue("documentFile", event.currentTarget.files[0])
                  }
                  className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage
                  className="text-red-500 text-sm"
                  name="documentFile"
                  component="div"
                />
              </div>
            </div>

            <button
              type="submit"
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
              rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
              focus:ring-gray-200 w-full mt-5 font-Poppins"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Update'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DocumentUploadComponent;
