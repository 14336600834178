// src/redux/actions.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  DOCTOR_SIGNUP_FAILURE,
  DOCTOR_SIGNUP_REQUEST,
  DOCTOR_SIGNUP_SUCCESS,
  VERIFY_OTP,
  OTP_SUCCESS,
  OTP_FAILURE,
  SUBMIT_DOCTOR_DETAIL_FAILURE,
  SUBMIT_DOCTOR_DETAIL_REQUEST,
  SUBMIT_DOCTOR_DETAIL_SUCCESS,
  SUBMIT_USER_DETAIL_FAILURE,
  SUBMIT_USER_DETAIL_REQUEST,
  SUBMIT_USER_DETAIL_SUCCESS,
  SUBMIT_USER_MEDICAL_DETAILS_FAILURE,
  SUBMIT_USER_MEDICAL_DETAILS_REQUEST,
  SUBMIT_USER_MEDICAL_DETAILS_SUCCESS,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_MEDICAL_DETAILS_SUCCESS,
  FETCH_USER_MEDICAL_DETAILS_FAILURE,
  FETCH_USER_MEDICAL_DETAILS_REQUEST,
  DOCTOR_APPOINTMENT_HISTORY_REQUEST,
  DOCTOR_APPOINTMENT_HISTORY_SUCCESS,
  DOCTOR_APPOINTMENT_HISTORY_FAILURE,
  UPLOAD_DOCUMENT,
  RETRIEVE_DOCUMENTS,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  DOCTOR_COUNCIL_REGISTRATION_DOCUMENT,
  FETCH_DOCTOR_PROFILE_REQUEST,
  FETCH_DOCTOR_PROFILE_SUCCESS,
  FETCH_DOCTOR_PROFILE_FAILURE,
} from "./actionsTypes";
import axios from "axios";
import { api, setAuthToken } from "../utils/Api";

// user login actions
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});
export const loginSuccess = (token, message) => ({
  type: LOGIN_SUCCESS,
  payload: { token, message },
});
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const loginUser = (credentials) => {
  const userType = "patient";
  return async (dispatch) => {
    dispatch(loginRequest());
    credentials.userType = userType;
    try {
      const response = await api.post(`/auth/login`, credentials).catch((error) => {
        throw error
      });
      const token = response.data.data.token;
      const userType = response.data.data.type;
      const successMessage = response.data.message; // Assuming the success message is in the API response

      // Store the token in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("type", userType);
      dispatch(loginSuccess(token, successMessage));
      // Set the token in the authorization header
      setAuthToken(token);
    } catch (errors) {
      throw errors;
    }
  };
};

// user signup actions
export const signupRequest = () => ({
  type: SIGNUP_REQUEST,
});
export const signupSuccess = (token, message) => ({
  type: SIGNUP_SUCCESS,
  payload: { token, message },
});
export const signupFailure = (errors) => ({
  type: SIGNUP_FAILURE,
  payload: errors,
});

const userType = "patient";
export const signupUser = (userData) => {
  return async (dispatch) => {
    dispatch(signupRequest());
    // Set the user type to "patient"
    userData.userType = userType;

    // Make the API request and handle the response
    try {
      const response = await api.post(`/auth/register`, userData);
      const successMessage = response.data.message; // Assuming the success message is in the API response
      dispatch(signupSuccess(successMessage));
      // Set the token in the authorization header
    } catch (errors) {
      dispatch(signupFailure(errors));

      throw errors; // Rethrow the error for proper handling in your component
    }
  };
};

// Action creators for doctor signup process
export const doctorSignupRequest = () => ({
  type: DOCTOR_SIGNUP_REQUEST,
});

export const doctorSignupSuccess = (message) => ({
  type: DOCTOR_SIGNUP_SUCCESS,
  payload: message,
});

export const doctorSignupFailure = (errors) => ({
  type: DOCTOR_SIGNUP_FAILURE,
  payload: errors,
});

export const signupDoctor = (userData) => {
  const userType = "doctor"; // Assuming '2' is the code for doctors
  return async (dispatch) => {
    dispatch(doctorSignupRequest());
    userData.userType = userType;
    try {
      const response = await api.post(`/auth/register`, userData);
      dispatch(doctorSignupSuccess(response.data.message));
      const token = response.data.token;
      setAuthToken(token);
      localStorage.setItem("token", token);
    } catch (error) {
      let errors = [];
      if (error.response && error.response.data && error.response.data.errors) {
        // Extract messages from the errors array
        errors = error.response.data.errors.map((err) => err.msg);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If there's a single message, use it
        errors = [error.response.data.message];
      } else {
        errors = ["An unexpected error occurred. Please try again later."];
      }
      dispatch(doctorSignupFailure(errors));
      throw new Error(errors.join(" "));
    }
  };
};

// OTP actions
export const verifyOtp = (otp) => ({
  type: VERIFY_OTP,
  payload: otp,
});
export const otpSuccess = (token, type, message) => {
  // Save the token to local storage
  localStorage.setItem("token", token);
  localStorage.setItem("type", type);
  return {
    type: OTP_SUCCESS,
    payload: { token, type, message },
  };
};
export const otpFailure = (error) => ({
  type: OTP_FAILURE,
  payload: error,
});

// Fetch specializations and cities
export const fetchSpecializations = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("/specialties");
      dispatch({
        type: "FETCH_SPECIALIZATIONS_SUCCESS",
        payload: response.data,
      });
    } catch (error) {}
  };
};
// Fetch specializations and cities
export const fetchCities = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("/cities");
      dispatch({ type: "FETCH_CITIES_SUCCESS", payload: response.data });
    } catch (error) {}
  };
};

// Submit doctor detail actions
export const submitDoctorDetailRequest = () => ({
  type: SUBMIT_DOCTOR_DETAIL_REQUEST,
});

export const submitDoctorDetailSuccess = () => ({
  type: SUBMIT_DOCTOR_DETAIL_SUCCESS,
});

export const submitDoctorDetailFailure = (error) => ({
  type: SUBMIT_DOCTOR_DETAIL_FAILURE,
  payload: error,
});

export const submitDoctorDetail = (doctorData) => {
  return async (dispatch) => {
    dispatch(submitDoctorDetailRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post("/doctors/profile", doctorData);
        const token = localStorage.getItem("token");
        const type = localStorage.getItem("type");
        dispatch(submitDoctorDetailSuccess(response.data, token, type)); // Assuming you fix the action creator to handle this properly
        resolve(response);
      } catch (error) {
        dispatch(submitDoctorDetailFailure(error));
        console.log(error);
        reject(error.response.data.message);
      }
    });
  };
};

// Submit user detail actions
export const submitUserDetailRequest = () => ({
  type: SUBMIT_USER_DETAIL_REQUEST,
});

export const submitUserDetailSuccess = (data) => ({
  type: SUBMIT_USER_DETAIL_SUCCESS,
  payload: data,
});

export const submitUserDetailFailure = (error) => ({
  type: SUBMIT_USER_DETAIL_FAILURE,
  payload: error,
});

// Submit user detail action (asynchronous)
export const submitUserDetail = (userData) => {
  return async (dispatch) => {
    dispatch(submitUserDetailRequest());

    try {
      // Make an API call to submit the user data
      const response = await api
        .post("/patients/profile", userData)
        .catch((error) => {
          throw error;
        });

      const successMessage = response.data.message;
      const token = response.data.data.token;
      const type = response.data.data.type;

      dispatch(submitUserDetailSuccess(successMessage, token, type));
    } catch (error) {
      console.log("ERROR FROM ACTIONS", error);
      dispatch(submitUserDetailFailure(error));
    }
  };
};

// Submit user medical details actions
export const submitUserMedicalDetailsRequest = () => ({
  type: SUBMIT_USER_MEDICAL_DETAILS_REQUEST,
});

export const submitUserMedicalDetailsSuccess = (data) => ({
  type: SUBMIT_USER_MEDICAL_DETAILS_SUCCESS,
  payload: data,
});

export const submitUserMedicalDetailsFailure = (error) => ({
  type: SUBMIT_USER_MEDICAL_DETAILS_FAILURE,
  payload: error,
});
// Submit user medical details actions
export const submitUserMedicalDetails = (medicalData) => {
  return async (dispatch) => {
    dispatch(submitUserMedicalDetailsRequest());

    try {
      // Make an API call to submit the user data
      const response = await api.post("/patients/medical-info", medicalData);
      const token = response.data.data.token;
      const type = response.data.data.type;
      const successMessage = response.data.data.message;
      dispatch(submitUserMedicalDetailsSuccess(successMessage, token, type));
    } catch (error) {
      dispatch(submitUserMedicalDetailsFailure(error));
    }
  };
};

// Action Creators user profile
export const fetchUserProfileRequest = () => ({
  type: FETCH_USER_PROFILE_REQUEST,
});

export const fetchUserProfileSuccess = (data) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: data,
});

export const fetchUserProfileFailure = (error) => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: error,
});

// Async Action to fetch user profile
export const fetchUserProfile = () => {
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest());

    const token = localStorage.getItem("token");
    const type = localStorage.getItem("type");

    if (token && type === "1") {
      try {
        const response = await api.get(`/patients/profile/`).catch((error) => {
          throw error
        });
        const userProfileData = response.data.data; // Extracting the full user profile data
        const profilePictureLink = userProfileData.profilePictureLink; // Extracting profile picture link

        // You can add the profile picture link to the user profile data, or dispatch it separately
        const payload = {
          ...userProfileData,
          profilePictureLink: profilePictureLink, // Adding the profile picture link to the payload
        };

        dispatch(fetchUserProfileSuccess(payload));
      } catch (errors) {
        dispatch(fetchUserProfileFailure(errors.message));
        if (errors.response && (errors.response.status === 401 || errors.response.status === 400)) {
          // Clear token and redirect to login page
          localStorage.removeItem('token');
          localStorage.removeItem('type');
          window.location.href = '/';
        }


      }
    } else {
      dispatch(fetchUserProfileFailure("Unauthorized user"));
    }
  };
};

// Action Creators doctor profile
// Action Creators for doctor profile
export const fetchDoctorProfileRequest = () => ({
  type: FETCH_DOCTOR_PROFILE_REQUEST,
});

export const fetchDoctorProfileSuccess = (data) => ({
  type: FETCH_DOCTOR_PROFILE_SUCCESS,
  payload: data,
});

export const fetchDoctorProfileFailure = (error) => ({
  type: FETCH_DOCTOR_PROFILE_FAILURE,
  payload: error,
});

// Async Action to fetch doctor profile
export const fetchDoctorProfile = () => {
  return async (dispatch) => {
    dispatch(fetchDoctorProfileRequest());

    const token = localStorage.getItem("token");
    const userType = localStorage.getItem("type");

    if (token && userType === "2") {
      try {
        const response = await api.get(`/doctors/profile`).catch((error) => {
          throw error
        });
        const userProfileData = response.data.data;
        const profilePictureLink = userProfileData.profilePic || ""; // Ensure there's a fallback value
        const payload = {
          ...userProfileData,
          profilePictureLink, // Simplified
        };

        dispatch(fetchDoctorProfileSuccess(payload));
      } catch (errors) {
        dispatch(fetchDoctorProfileFailure(errors.message));
        if (errors.response && (errors.response.status === 401 || errors.response.status === 400)) {
          // Clear token and redirect to login page
          localStorage.removeItem('token');
          localStorage.removeItem('type');
          window.location.href = '/';
        }
      }
    } else {
      console.log("Unauthorized or invalid user type");
      dispatch(
        fetchDoctorProfileFailure("Unauthorized user or invalid user type")
      );
    }
  };
};


// Action Creators user medical details
export const fetchUserMedicalDetailsRequest = () => ({
  type: FETCH_USER_MEDICAL_DETAILS_REQUEST,
});

export const fetchUserMedicalDetailsSuccess = (data) => ({
  type: FETCH_USER_MEDICAL_DETAILS_SUCCESS,
  payload: data,
});

export const fetchUserMedicalDetailsFailure = (error) => ({
  type: FETCH_USER_MEDICAL_DETAILS_FAILURE,
  payload: error,
});

// Async Action to fetch user profile
export const fetchUserMedicalDetails = () => {
  return (dispatch) => {
    dispatch(fetchUserMedicalDetailsRequest());
    const token = localStorage.getItem("token");

    if (token) {
      api
        .get(`/patients/medical-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const userMedicalData = response.data.data;
          dispatch(fetchUserMedicalDetailsSuccess(userMedicalData));
        })
        .catch((error) => {
          dispatch(fetchUserMedicalDetailsFailure(error.message));
        });
    } else {
      dispatch(fetchUserMedicalDetailsFailure("Unauthorized user"));
    }
  };
};

// Action Creators doctor appointment history
export const fetchDoctorAppointmentsHistoryRequest = () => ({
  type: DOCTOR_APPOINTMENT_HISTORY_REQUEST,
});

export const fetchDoctorAppointmentsHistorySuccess = (data) => ({
  type: DOCTOR_APPOINTMENT_HISTORY_SUCCESS,
  payload: data,
});

export const fetchDoctorAppointmentsHistoryFailure = (error) => ({
  type: DOCTOR_APPOINTMENT_HISTORY_FAILURE,
  payload: error,
});

// Async Action to fetch doctor appointment history
export const fetchDoctorAppointmentsHistory = () => {
  return (dispatch) => {
    dispatch(fetchDoctorAppointmentsHistoryRequest());

    const token = localStorage.getItem("token");
    const type = localStorage.getItem("type");

    // Check if the user is authorized
    if (token && type === "1") {
      api
        .get(`/patients/appointments`)
        .then((response) => {
          const doctorAppointmentData = response.data.data; // Assuming your data structure matches the example
          dispatch(
            fetchDoctorAppointmentsHistorySuccess(doctorAppointmentData)
          );
        })
        .catch((error) => {
          dispatch(fetchDoctorAppointmentsHistoryFailure(error.message));
        });
    } else {
      // Handle unauthorized user (e.g., redirect to a login page or show an error)
      dispatch(fetchDoctorAppointmentsHistoryFailure("Unauthorized user"));
    }
  };
};

// Action to upload a document
export const uploadDocument = (document) => ({
  type: UPLOAD_DOCUMENT,
  payload: document,
});

// Action to retrieve all documents
export const retrieveDocuments = (documents) => ({
  type: RETRIEVE_DOCUMENTS,
  payload: documents,
});

// Action to update a document
export const updateDocument = (id, updatedDocument) => ({
  type: UPDATE_DOCUMENT,
  payload: { id, updatedDocument },
});

// Action to delete a document
export const deleteDocument = (id) => ({
  type: DELETE_DOCUMENT,
  payload: id,
});

// Action to upload a document
export const uploadDoctorCouncilDocument = (document) => ({
  type: DOCTOR_COUNCIL_REGISTRATION_DOCUMENT,
  payload: document,
});
