import React from 'react'
import DoctorNavBar from '../../components/DoctorNavBar'
import Wallet from '../../components/doctorDashboard/Wellet'
import Lottie from 'lottie-react';
import wallet from'../../lottiefiles/wallet.json'

const WalletPage = () => {
  return (
    <div>
        <DoctorNavBar/>

        <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-gray-100 pt-16'>
          <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
            <div className='sm:w-1/2 flex justify-center items-center'>
              <Lottie className='w-full' animationData={wallet} loop={true} />
            </div>
            <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='text-xl  text-center font-bold font-Poppins'>Welcome to you wallet</h1>
              <p className='text-center font-Poppins'>
                Here you can see your current balance, available balance and the amount you have earned from kekecare,
                you can also withdraw your money from here.
              </p>
            </div>
          </div>
        </div>

        <div className=' mt-24 lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:flex-row flex-col w-full '>
        <Wallet/>
        </div>
    </div>
  )
}

export default WalletPage
