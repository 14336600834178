import React from 'react'
import Lottie from 'lottie-react';
import in_progress from '../../../lottiefiles/in_progress.json'
import { Link } from 'react-router-dom';

const AddFamilyMember = () => {
  return (
    <div className='bg-gray-100 w-screen h-screen flex flex-col justify-center items-center'>
        <p className='text-gray-600 font-Poppins'>this page is still in development</p>
        <p className='text-gray-600 font-Poppins'>please check back later</p>
        <Link
        to={'/settings_page'}
        className='text-blue-600 flex mt-5 hover:text-blue-500 hover:gap-2 hover:cursor-pointer'>
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7"/>
        </svg>
            <p className='font-semibold font-Poppins'>Back to settings</p>
        </Link>
        <div className='sm:w-1/2 flex justify-center items-center'>
            <Lottie className='w-1/2' animationData={in_progress} loop={true} />
        </div>
    </div>
  )
}

export default AddFamilyMember
