import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';
import ShareDocumentPopup from './ShareDocumentPopup';

function DocumentTableComponent() {
    const [searchTerm, setSearchTerm] = useState('');
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [showSharePopup, setShowSharePopup] = useState(false); // State to control visibility of share document popup
    const [selectedDocumentId, setSelectedDocumentId] = useState(null); // State to store the id of the document to be shared

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await api.get('/patients/medical-records');
                if (response.data.status === "success") {
                    setDocuments(response.data.data);
                } else {
                    setDocuments([]);
                }
            } catch (error) {
                console.error("API request error:", error);
                setDocuments([]);
            } finally {
                setIsLoading(false); // Set loading state to false after fetching data
            }
        };

        fetchDocuments();

        const intervalId = setInterval(fetchDocuments, 5000); // Poll every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []); // Only run this effect on mount

    const handleEdit = async (id, updatedName) => {
        try {
            const response = await api.put(`/patients/medical-records/${id}`, { documentTitle: updatedName });
            if (response.data && response.data.status === "success") {
                const updatedDocs = documents.map(doc =>
                    doc.documentId === id ? { ...doc, ...response.data.updatedDocument } : doc
                );
                setDocuments(updatedDocs);
            } else {
                throw new Error('Update was not successful.');
            }
        } catch (error) {
            console.error('Error updating document', error);
        }
    };

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this document?");

        if (isConfirmed) {
            try {
                await api.delete(`/patients/medical-records/${id}`);
                const filteredDocs = documents.filter(doc => doc.documentId !== id);
                setDocuments(filteredDocs);
            } catch (error) {
                console.error('Error deleting document', error);
            }
        } else {
            console.log("Delete operation was canceled.");
        }
    };

    const handleShareDocument = (documentId) => {
        setSelectedDocumentId(documentId);
        setShowSharePopup(true);
    };

    const filteredDocuments = documents.filter(doc =>
        doc.documentTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.documentId.toString().includes(searchTerm)
    );

    return (
        <div>
            <input
                type="text"
                placeholder="Search by Name"
                onChange={e => setSearchTerm(e.target.value)}
                className="border border-blue-400 rounded sm:w-96 w-64 text-sm p-2 m-2"
            />

                {isLoading && (
                  <div className="flex justify-center items-center h-64">
                    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
                    h-32 w-32 flex justify-center items-center"
                    >
                      <p className="text-gray-500 ml-4 text-sm">Loading...</p>
                    </div>
                  </div>
                )}
            {filteredDocuments.length > 0 ? (
            <table className="min-w-full border-collapse border rounded font-Poppins overflow-x-scroll">
                <thead className='bg-gray-50 text-gray-700 text-sm'>
                    <tr>
                        <th className="border p-2">Name</th>
                        <th className="border p-2">Document</th>
                        <th className="border p-2 sm:w-96">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredDocuments.map(doc => (
                        <tr key={doc.documentId} className='text-gray-600'>
                            <td className="border p-2">{doc.documentTitle}</td>
                            <td className="border p-2">
                                <div className='flex'>
                                    <svg class="flex-shrink-0 w-5 h-5 text-blue-500 transition duration-75  group-hover:text-white"
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M9 2.2V7H4.2l.4-.5 3.9-4 .5-.3Zm2-.2v5a2 2 0 0 1-2 2H4v11c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Z" clip-rule="evenodd"/>
                                    </svg>
                                <a href={doc.documentUrl} target="_blank" className='ml-2 hover:text-blue-500' rel="noopener noreferrer">View Document</a>
                                </div>

                            </td>
                            <td className=" p-2 flex sm:flex-row flex-col justify-center items-center" role="group">

                                <button type="button"
                                onClick={() => handleEdit(doc.documentId, "New Document Title")}
                                    class="inline-flex items-center px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white sm:border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-2 focus:ring-green-700 focus:text-green-700 ">
                                     <svg class="sm:w-4 w-3 sm:h-4 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M11.3 6.2H5a2 2 0 0 0-2 2V19a2 2 0 0 0 2 2h11c1.1 0 2-1 2-2.1V11l-4 4.2c-.3.3-.7.6-1.2.7l-2.7.6c-1.7.3-3.3-1.3-3-3.1l.6-2.9c.1-.5.4-1 .7-1.3l3-3.1Z" clip-rule="evenodd"/>
                                        <path fill-rule="evenodd" d="M19.8 4.3a2.1 2.1 0 0 0-1-1.1 2 2 0 0 0-2.2.4l-.6.6 2.9 3 .5-.6a2.1 2.1 0 0 0 .6-1.5c0-.2 0-.5-.2-.8Zm-2.4 4.4-2.8-3-4.8 5-.1.3-.7 3c0 .3.3.7.6.6l2.7-.6.3-.1 4.7-5Z" clip-rule="evenodd"/>
                                    </svg>
                                        Edit
                                </button>

                                <button type="button"
                                onClick={() => handleShareDocument(doc.documentId)}
                                    class="inline-flex items-center px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white sm:border-t sm:border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                                   <svg class="sm:w-4 w-3 sm:h-4 h-3 me-2"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M5 10.9a8.7 8.7 0 0 1 6.4-3.6V6a2 2 0 0 1 2.3-2c.4 0 .7.1 1 .3l5.5 4.3a2.1 2.1 0 0 1 0 3.3l-5.5 4.3a2 2 0 0 1-2 .3 2 2 0 0 1-1.2-1.9v-1C6 15 5.2 19 5.2 19.3a1 1 0 0 1-1 .8 1 1 0 0 1-1-.7A10.2 10.2 0 0 1 5 10.9Z"/>
                                    </svg>
                                    Share
                                </button>

                                {showSharePopup && (
                                    <ShareDocumentPopup
                                        documentId={selectedDocumentId}
                                        onClose={() => setShowSharePopup(false)}
                                    />
                                )}

                                <button type="button"
                                onClick={() => handleDelete(doc.documentId)}
                                    class="inline-flex items-center px-4 py-2 sm:text-sm text-xs font-medium text-gray-900 bg-white sm:border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 e">
                                    <svg class="sm:w-4 w-3 sm:h-4 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M8.6 2.6A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4c0-.5.2-1 .6-1.4ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd"/>
                                    </svg>
                                    Delete
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>) : (
          // Displayed when no appointments are present
          <div className="col-span-full text-center py-8">
          <p className="text-gray-500 font-Poppins">{isLoading ? '' : 'No document uploaded yet.'}</p>
        </div>
        )}
        </div>
    );
}

export default DocumentTableComponent;
