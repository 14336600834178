import React from 'react';
import DoctorNavBar from '../../components/DoctorNavBar';
import DoctorProfileDetails from '../../components/doctorDashboard/DoctorDetailProfile';
import profile_bg from '../../images/profile_bg.jpg';
import GetCouncilRegistration from '../../components/doctorDashboard/GetCouncilRegistration';

const DoctorProfile = () => {
  return (
    <div className='overflow-x-hidden'>
      <DoctorNavBar/>
      <div className='w-full h-60 flex justify-center lg:px-0 px-3 items-center
        bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url(${profile_bg})` }}>
          {/* <h1 className='text-white text-5xl font-bold lg:ml-72 '>User Profile</h1> */}
      </div>

      <div className='w-full -mt-28 lg:ml-72 flex lg:px-0 px-4 sm:flex-row flex-col sm:gap-0 gap-7'>
        <DoctorProfileDetails/>
      </div>

      <div className='w-full mt-8 lg:ml-72 flex lg:px-0 px-4 sm:flex-row flex-col sm:gap-0 gap-7 border-t pt-6'>
        <GetCouncilRegistration/>
        </div>
    </div>
  );
};

export default DoctorProfile;
