import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

export default function PrivateDoctorRoute (){
    const token = localStorage.getItem('token');
    const type = localStorage.getItem('type');

    const isUserLoggedIn = token && type === '1';
    const isDoctorLoggedIn = token && type === '2';

    return (
      <>
        {isUserLoggedIn ? (
          <Navigate to="/userDashboard" />
        ) : isDoctorLoggedIn ? (
          <Navigate to="/doctorDashboard" />
        ) : (
          <Navigate to="/" />
        )}
        <Outlet />
      </>
    );
}

