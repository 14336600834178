import React, { useState } from 'react';
import { api } from '../../utils/Api';

const UserPrescription = ({ setShowPrescription, appointmentId  }) => {
  const [prescriptions, setPrescriptions] = useState([]); // To hold prescription list
  const [selectedPrescription, setSelectedPrescription] = useState(null); // To hold selected prescription ID
  const [otp, setOtp] = useState(''); // To hold user OTP input
  const [showOtpPopup, setShowOtpPopup] = useState(false); // To control OTP input popup
  const [showDetailsPopup, setShowDetailsPopup] = useState(false); // To control prescription details popup
  const [prescriptionDetails, setPrescriptionDetails] = useState(null); // To hold prescription details
  const [errorMessage, setErrorMessage] = useState(''); // To handle errors

    // Fetch prescriptions for the patient using dynamic appointmentId
    const fetchPrescriptions = async () => {
      try {
        const response = await api.get(`patients/prescriptions/appointment/${appointmentId}`);
        setPrescriptions(response.data.data);
      } catch (error) {
        console.error('Error fetching prescriptions', error);
      }
    };


  // Function to send OTP when a prescription is clicked
  const sendOtpForPrescription = async (prescriptionId) => {
    try {
      await api.get(`/patients/prescriptions/${prescriptionId}?token`);
      setSelectedPrescription(prescriptionId); // Store the selected prescription ID
      setShowOtpPopup(true); // Show OTP input popup
      setErrorMessage(''); // Clear error message
    } catch (error) {
      console.error('Error sending OTP', error);
      setErrorMessage('Failed to send OTP. Please try again.');
    }
  };

  // Function to fetch prescription details with OTP
  const fetchPrescriptionDetailsWithOtp = async () => {
    if (!otp) {
      setErrorMessage('OTP is required to view prescription details');
      return;
    }

    try {
      const response = await api.get(`/patients/prescriptions/${selectedPrescription}?token=${otp}`);
      setPrescriptionDetails(response.data);
      setShowDetailsPopup(true); // Show details popup
      setShowOtpPopup(false); // Hide OTP popup
      setErrorMessage(''); // Clear error message
    } catch (error) {
      console.error('Error fetching prescription details', error);
      setErrorMessage('Invalid OTP or failed to fetch details');
    }
  };

  // Run the fetch when the component loads
  React.useEffect(() => {
    fetchPrescriptions();
  }, []);

  return (
    <div className="sm:pl-72 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="h-fit sm:w-[35rem] w-full max-h-[40rem] bg-white rounded overflow-y-auto">
        <button
          onClick={() => setShowPrescription(false)}
          className="mt-3 ml-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
        >
          X
        </button>
        <div className="flex flex-col justify-center items-center h-full p-4">
          <h3 className='text-lg font-semibold'>Prescriptions</h3>
          {/* Prescription List */}
          {prescriptions.length ? (
            <ul className="w-full text-sm text-gray-600">
              {prescriptions.map((prescription) => (
                <li
                  key={prescription.id}
                  onClick={() => sendOtpForPrescription(prescription.id)}
                  className="cursor-pointer p-2 border-b hover:bg-gray-100 flex flex-col"
                >
                  <p> Prescription ID: <span className='text-gray-900'>{prescription.prescrtiptionId}</span> </p>
                  <p> Appointment ID: <span className='text-gray-900'>{prescription.appointmentId}</span>  </p>
                  <p> Prescription Date: <span className='text-gray-900'>{prescription.createdAt}</span> </p>
                  <p> Prescription Update Date: <span className='text-gray-900'>{prescription.updatedAt}</span> </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No prescriptions available</p>
          )}

          {/* Error Message */}
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      </div>

      {/* OTP Input Popup */}
      {showOtpPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
          <div className="h-[15rem] w-[20rem] bg-white rounded p-4">
            <button
              onClick={() => setShowOtpPopup(false)}
              className="mb-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
            >
              Close
            </button>
            <div>
              <h2 className="text-lg font-bold mb-2">Enter OTP to View Prescription Details</h2>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                className="w-full px-3 py-1 border rounded"
              />
              <button
                onClick={fetchPrescriptionDetailsWithOtp}
                className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Submit OTP
              </button>

              {/* Error Message */}
              {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </div>
          </div>
        </div>
      )}

      {/* Prescription Details Popup */}
      {showDetailsPopup && prescriptionDetails && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
          <div className="h-[25rem] w-[25rem] bg-white rounded p-4">
            <button
              onClick={() => setShowDetailsPopup(false)}
              className="mb-3 px-3 py-1 rounded-full bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600"
            >
              Close
            </button>
            <div>
              <h2 className="text-lg font-bold mb-2">Prescription Details</h2>
              <p><strong>ID:</strong> {prescriptionDetails.id}</p>
              <p><strong>Name:</strong> {prescriptionDetails.name}</p>
              <p><strong>Description:</strong> {prescriptionDetails.description}</p>
              {/* Render other prescription details */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPrescription;
