import React, { useState, useEffect } from "react";
import { api } from "../../utils/Api";

const GetCouncilRegistration = () => {
  const [registrationData, setRegistrationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchCouncilRegistration = async () => {
      try {
        const response = await api.get("/doctors/council-registration");
        setRegistrationData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCouncilRegistration();
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      await api.put("/doctors/council-registration", registrationData);
      setEditMode(false);
      console.log("Registration details updated successfully!");
    } catch (error) {
      console.error("Error updating registration details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData({
      ...registrationData,
      [name]: value
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="grid grid-cols-2 gap-4">
      {registrationData && (
        <>

          <div className="grid grid-cols-3 gap-4 text-sm text-gray-700 w-fill">
            <h2 className="font-bold col-span-3">Registration Details:</h2>
            {editMode ? (
              <form onSubmit={handleSave}>
                <div className="flex gap-4">

										<label>
                    Council Name:
                    <input type="text" name="councilName" value={registrationData.councilName} onChange={handleChange} />
                    </label>



                    <label>
                    Registration Number:
                    <input type="text" name="regNumber" value={registrationData.regNumber} onChange={handleChange} />
                    </label>

                    <label>
                    Registration Year:
                    <input type="text" name="regYear" value={registrationData.regYear} onChange={handleChange} />
                    </label>

                    <label>
                    Issued Date:
                    <input type="text" name="certIssuedDate" value={registrationData.certIssuedDate} onChange={handleChange} />
                    </label>

                    <label>
                        Expiry Date:
                    <input type="text" name="certExpiryDate" value={registrationData.certExpiryDate} onChange={handleChange} />
                    </label>
                </div>



                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-7">
                  Save
                </button>
              </form>
            ) : (
              <>
                <p>Council Name: {registrationData.councilName}</p>
                <p>Registration Number: {registrationData.regNumber}</p>
                <p>Registration Year: {registrationData.regYear}</p>
                <p>Registration certificate Issued Date: {registrationData.certIssuedDate}</p>
                <p>Registration certificate Expiry Date: {registrationData.certExpiryDate}</p>
                <p>Registration Status: {registrationData.regStatus}</p>

              </>
            )}
          </div>

          <div className="ml-5 text-sm text-gray-700">
            <h2 className="font-bold">Registration Documents:</h2>
            <p>
              <a href={registrationData.regDocumentUrl} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </p>
            <button
              className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded mt-7"
              onClick={editMode ? handleSave : handleEdit}
            >
              {editMode ? 'Save' : 'Edit documents'}
            </button>
          </div>

        </>
      )}
    </div>
  );
};

export default GetCouncilRegistration;
